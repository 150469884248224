/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

/**
 * Base class for API services
 */
 @Injectable()
export class BaseService {
	constructor(
		protected config: ApiConfiguration,
		protected http: HttpClient,
	) {
	}

	private _rootUrl: string;

	/**
	 * Returns the root url for API operations. If not set directly in this
	 * service, will fallback to ApiConfiguration.rootUrl.
	 */
	get rootUrl(): string {
		if (environment.ciDevUrl) {
			return environment.ciDevUrl
		}
		return this._rootUrl || this.config.rootUrl;
	}

	/**
	 * Sets the root URL for API operations in this service.
	 */
	set rootUrl(rootUrl: string) {
		this._rootUrl = rootUrl;
	}
}
