import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { WorkerClient, WorkerManager } from 'angular-web-worker/angular';
import { RootState } from 'app/app.module';
import { of, from } from 'rxjs';
import {
	concatMap, withLatestFrom, mergeMap, map, switchMap, debounceTime,
} from 'rxjs/operators';
import * as _ from 'lodash';
import { filterOdometers, loadOdometers, loadOdometersSuccess } from './odometer.actions';
import { odometerFeature } from './odometer.selector';
import { OdometerService } from './odometer.service';
import { OdometerState } from './odometer.types';
import { OdometerWorker } from './odometer.worker';

@Injectable()
export class OdometerEffects {
	private client: WorkerClient<OdometerWorker>;

	@Effect() loadOdometer = this.actions$.pipe(
		ofType(loadOdometers),
		debounceTime(1000),
		concatMap((action) => of(action).pipe(
			withLatestFrom(this.route.queryParams, this.store.select(odometerFeature)),
		)),
		mergeMap(([action, params, state]: [TypedAction<string>, Params, OdometerState]) => this.odometerService.getReportDetailsData(params).pipe(
			switchMap((odometer) => {
				const nState = {
					...state,
					jumpEvents: [...odometer],
				};
				this.initWebWorker();
				return from(this.callWorkerMethod(nState, params)).pipe(mergeMap(
					(data) => {
						this.client.destroy();
						return [loadOdometersSuccess(data)];
					},
				));
			}),
		)),
	);

	@Effect() filterOdometer = this.actions$.pipe(
		ofType(filterOdometers),
		concatMap((action) => of(action).pipe(
			withLatestFrom(this.route.queryParams, this.store.select(odometerFeature)),
		)),
		mergeMap(([action, params, state]: [TypedAction<string>, Params, OdometerState]) => {
			this.initWebWorker();
			return from(this.callWorkerMethod(state, params)).pipe(
				map((data) => {
					this.client.destroy();
					return loadOdometersSuccess(data);
				}),
			);
		}),
	);

	initWebWorker() {
		if (this.workerManager.isBrowserCompatible) {
			this.client = this.workerManager.createClient(OdometerWorker);
		} else {
			// if code won't block UI else implement other fallback behaviour
			this.client = this.workerManager.createClient(OdometerWorker, true);
		}
	}

	async callWorkerMethod(dat, params) {
		let outcome;
		try {
			await this.client.connect();
			outcome = await this.client.call((w) => w.doSomeWork(dat, params));
		} catch (exception) {
			console.error(exception);
			return null;
		}
		return outcome;
	}

	constructor(
		private route: ActivatedRoute,
		private store: Store<RootState>,
		private actions$: Actions,
		private workerManager: WorkerManager,
		private odometerService: OdometerService,
	) { }
}
