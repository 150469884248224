import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartComponent } from './highchart.component';

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		HighchartComponent,
	],
	providers: [
	],
	declarations: [
		HighchartComponent,
	],
})
export class HighchartModule { }
