/*
 * This module imports and re-exports all Angular Material modules for convenience,
 * so only 1 module import is needed in your feature modules.
 * See https://material.angular.io/guide/getting-started#step-3-import-the-component-modules.
 *
 * To optimize your production builds, you should only import the components used in your app.
 */

import { NgModule } from '@angular/core';
import { ContextualHelpDirective } from './contextual-help.directive';

@NgModule({
	exports: [
		ContextualHelpDirective,
	],
	declarations: [
		ContextualHelpDirective,
	],
})
export class DirectivesModule { }
