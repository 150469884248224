import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material/material.module';
import { SidebarComponent } from './sidebar.component';

@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
	],
	exports: [
		SidebarComponent,
	],
	declarations: [
		SidebarComponent,
	],
})
export class SideBarModule {
}
