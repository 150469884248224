import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

export function usualFormat(value, timezone) {
	if (!value || !timezone) { return null; }
	return moment(value).tz(timezone).format('MM/DD/YYYY h:mma z');
}

/**
* A moment timezone pipe to support parsing based on time zone abbreviations
* covers all cases of offset variation due to daylight saving.
*
* Same API as DatePipe with additional timezone abbreviation support
* Official date pipe dropped support for abbreviations names from Angular V5
*/
@Pipe({
	name: 'dateTimeTimezone',
})
export class DateTimeTimezonePipe implements PipeTransform {
	transform(
		value: string,
		timezone?: string,
	): string {
		if (!Date.parse(value)) {
			return value;
		}

		if (!timezone) {
			const browserTimezone = new Date().toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
			const time = moment(value);
			return `${time.format('MM/DD/YYYY')} ${time.format('h:mma')} ${browserTimezone}`;
		}
		return usualFormat(value, timezone);
	}
}
