import { OptionList } from '../../utils/options';

export interface MalfunctionTypes {
	POWER: number;
	DATA_TRANSFER: number;
	ENGINE_SYNCH: number;
	POSITIONING: number;
	DATA_RECORDING: number;
	TIMING: number;
	// OTHER: number; this is a valid malfunction type too - need to address with design
}

export const MalfunctionDefinitions = {
	POWER: 'Power',
	DATA_TRANSFER: 'Data Transfer',
	ENGINE_SYNCH: 'Engine Sync',
	POSITIONING: 'Position',
	DATA_RECORDING: 'Data Recording',
	TIMING: 'Timing',
	OTHER: 'Other',
};

export interface MostRecentDriver {
	id: string;
	firstName: string;
	lastName: string;
	location: string;
	timezone: string;
}

export interface Malfunction {
	id: string; // uuid
	assetId: string; // uuid
	companyId: string; // uuid
	divisionId: string; // uuid
	malfunctionType: string;
	divisionName: string;
	startTime: string;
	endTime: string;
	assetNumber: string;
	startingDriverProfileId: string; // uuid
	driverFirstName: string;
	driverLastName: string;
	mostRecentDriver: MostRecentDriver;
	// most_recent_driver: string; // uuid
	// most_recent_driver_first_name: string;
	// most_recent_driver_last_name: string;
	// most_recent_driver_location: string;
	activeMalfunction: boolean;
	currentDriver?: string;
	driverName?: string; // generated from first and last name in addExtraFields()
	malfunctionPeriod?: number; // generated from startTime and endTime and current time, in withPeriod()
	displayMalfunctionType?: string; // generated from malfunctionType in tableModel()
	driversAffected?: number;
	affectedDriversNames: Array<string>;
	timezone?: string;
	malfunctionDriverName?: string;
	total_malfunctions_in_group?: number;
}

export interface AssetMalfunctions {
	assetId: string;
	assetDBId: string;
	total: number;
	types: string[];
	impactedDrivers: string[];
	displayTypes: string;
	displayImpactedDrivers: string;
	malfunctions: Malfunction[];
}

export interface MalfunctionsTable {
	headers: Array<string>;
	displayedColumns: Array<string>;
	rows: Partial<Malfunction>[];
}

export interface MalfunctionParams {
	assetId?: string;
}

interface ChartData {
	metric: string;
	chart: Array<{
		type: string,
		name: string,
		data: Array<any>,
		colorByPoint: boolean,
		colors: Array<string>
	}>;
}

export interface MalfunctionChart {
	metric: string;
	chart: Array<ChartData>;
}

export interface MalfunctionDriver {
	driverId: any;
	time: string;
	isLogin: boolean;
	driverFirstName: string;
	driverLastName: string;
}

export interface FilterInputData {
	malfunctions: Partial<Malfunction>[];
	totalAssets: string;
	totalDrivers: string;
}

export interface MalfunctionTypeData {
	id: string,
	count: number
}

export interface KpiData {
	totalDrivers?: number,
	totalAssets?: number,
	foundMalfunctions?: number,
	malfunctionTypes?: MalfunctionTypeData[],
	totalMalfunctions?: number;
	topMalfunctionType?: string;
	impactedAssets: number;
	impactedDrivers: number;
}

export interface State {
	malfunctionType: string;
	malfunctionTypeDropdowns: OptionList;
	tableAssetOptions: OptionList;
	tableDivisionOptions: OptionList;
	tableDriverOptions: OptionList;
	kpiData: KpiData;
	malfunctions: Partial<Malfunction>[];
	tableData: Array<MalfunctionsTable>;
	chartData: ChartData;
	homeTile: {
		malfunctions: Partial<Malfunction>[],
		chartData: ChartData;
	};
	isLoading: boolean;
	isLoadSuccess: boolean;
	totalAssets: string;
	totalDrivers: string;
}
