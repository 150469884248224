import * as convert from 'convert-units';
import * as moment from 'moment-timezone';

const TwoMinutes = 120000;
const OneMinute = 60000;
const OneHour = 3600000;

const convertDurationToMinutes = (value: number, unit: string): string => {
	let result;
	const minutes: number = Math.floor(Number(convert(value).from(unit).to('min')));

	if (minutes < 1) {
		result = 'Less Than 1 Minute';
	} else if (minutes === 1) {
		result = '1 Minute';
	} else {
		result = `${minutes} Minutes`;
	}

	return result;
};

const secondsToHoursMinutes = (seconds) => {
	const hours = Math.floor(seconds / 60 / 60);
	const minutes = Math.floor(seconds / 60) - (hours * 60);

	return `${leadingZero(hours.toString())}:${leadingZero(minutes.toString())}`;
};

const leadingZero = (numStr) => (numStr && numStr.length === 1 ? `0${numStr}` : numStr);

const formatTime = (value, timezone?) => {
	if (!timezone) {
		const browserTimezone = new Date().toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
		const time = moment(value);
		return `${time.format('MM/DD/YYYY')} ${time.format('h:mma')} ${browserTimezone}`;
	}
	if (!value || !timezone) { return null; }
	return moment(value).tz(timezone).format('MM/DD/YYYY h:mma z');
};

const secondsToHoursMinutesSeconds = (value: number): string => {
	if (!value) { return `${value}`; }
	return moment.utc(value * 1000).format('HH:mm:ss');
};

export {
	OneMinute,
	TwoMinutes,
	OneHour,
	convertDurationToMinutes,
	secondsToHoursMinutes,
	formatTime,
	secondsToHoursMinutesSeconds,
	leadingZero
};
