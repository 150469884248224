import { OptionList } from 'app/utils/options';
import { Availability } from '../availability/availability.types';
import { Sds } from '../sds/sds.types';

export interface HoursState {
	isLoading?: boolean;
	isLoadSuccess?: boolean;
	tableData: TableData;
	tableDataByDriver: TableDataByDriver;
	offset: number;
	limit: number;
	totalNumberOfDrivers: number;
	hoursData: Sds[];
	chartData: {
		[key: string]: number;
	};
	filterOptions: {
		[key: string]: OptionList
	};
	isError: boolean;
	errorObj?: any;
}

export interface TableData {
	headers: string[];
	rows: Days[];
	displayedColumns: string[];
	unsorted?: boolean;
}

export interface TableDataByDriver {
	headers: string[];
	rows: Array<HoursDriverRow>;
	displayedColumns: string[];
	unsorted?: boolean;
}

export interface HoursRow {
	start_epoch: number | string;
	end_epoch: number | string;
	statusType: string;
	driverHomeLocation: string;
	total_time: number | string;
	driverId: string | number;
	driverName: string;
	exsid: number | string;
	tagid: number | string;
	ruleset: string | number;
	ruleset_name: string;
	timezone: string;
	location: string;
	activeStatus: boolean;
}

export interface Days {
	date: string;
	offHours: number;
	sbHours: number;
	driveHours: number;
	onHours: number;
	timezone?: string;
	csvDate?: any;
}

export interface HoursDriverRow {
	driverName: string;
	driverHomeLocation: string | number;
	onHours: string | number;
	offHours: string | number;
	driveHours: string | number;
	onDriveHours: string | number;
	hoursEvents: Days[];
	sbHours: string | number;
	driverId: string;
	ruleset_name: string;
	ruleset: string | number;
	exsid: string;
	tagid: string;
	averageOnHours: string | number;
	averageOffHours: string | number;
	averageSBHours: string | number;
	averageDriveHours: string | number;
}

export interface HoursHttpErrorResponse {
	message?: string;
	name?: string;
	statusText?: string;
}

export interface Location {
	id: number;
	lat: number;
	long: number;
	name: string;
}

export interface DriverInfo {
	driver_id: number;
	first_name: string;
	last_name: string;
	status: number;
	exsid: string;
	home_location: Location;
	timezone?: string;
}

export enum DutyStatus {
	OffHours= 1,
	SbHours,
	DriveHours,
	OnHours,
}

export interface DutyStatusTotal {
	1: number;
	2: number;
	3: number;
	4: number;
}

export interface V2HoursHttpResponse {
	driver_info: DriverInfo;
	duty_status_totals: any;
}

export interface ChartData {
	OFF: number;
	ON: number;
	DRIVE: number;
	SB: number,
};
