import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from '../../api/api-configuration';
import { BaseService } from '../../api/base-service';

@Injectable()
export class DriverListService extends BaseService {
	private httpUrl = `${this.rootUrl}/api/v2/driverList`;

	constructor(config: ApiConfiguration, http?: HttpClient) {
		super(config, http);
	}

	get(arg) {
		return this.http.get(this.httpUrl, {
			params: arg,
		});
	}
}
