import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import {HosViolationsLiveStatusState } from '../../reports/hos-violations/hos-violation-types';

export const GET_VIOLATIONS_LIVE_STATUS_SUCCESS = '[Get] GET_VIOLATIONS_LIVE_STATUS_SUCCESS';
export const GET_VIOLATIONS_LIVE_STATUS = '[Get] GET_VIOLATIONS_LIVE_STATUS';

export class GetHosViolationsLiveStatus implements Action {
	readonly type = GET_VIOLATIONS_LIVE_STATUS;
	constructor (public payload: Observable<Params>) {}
}

export class GetHosViolationsLiveStatusSuccess implements Action {
	readonly type = GET_VIOLATIONS_LIVE_STATUS_SUCCESS;
	constructor(public payload: HosViolationsLiveStatusState) {}
}

export type Actions =
	| GetHosViolationsLiveStatusSuccess
	| GetHosViolationsLiveStatus
