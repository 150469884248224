import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
	withLatestFrom, mergeMap, catchError, concatMap, map, debounceTime, switchMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootState } from 'app/app.module';
import { ActivatedRoute, Params } from '@angular/router';
import { TypedAction } from '@ngrx/store/src/models';
import * as _ from 'lodash';
import { EMPTY, of, from } from 'rxjs';
import { WorkerManager, WorkerClient } from 'angular-web-worker/angular';
import { SdsState } from './sds.types';
import { sdsFeature } from './sds.selectors';
import { SdsService } from './sds.service';
import {
	loadSds, loadSdsSuccess, loadSdsRange, filterSds,
} from './sds.actions';
import { SdsWorker } from './sds.worker';

@Injectable()
export class SdsEffects {
	private client: WorkerClient<SdsWorker>;

	@Effect() LoadSds = this.actions$.pipe(
		ofType(loadSds, loadSdsRange),
		debounceTime(1000),
		concatMap((action) => of(action).pipe(
			withLatestFrom(this.route.queryParams, this.store.select(sdsFeature)),
		)),
		mergeMap(([action, params, state]: [TypedAction<string>, Params, SdsState]) => this.sdsService.getReportDetailsData({
			offset: _.get(state, 'offset'),
			limit: _.get(state, 'limit'),
			...params,
		}).pipe(
			switchMap((sds) => {
				const nState = {
					...state,
					totalNumberOfDrivers: sds.totalNumberOfDrivers,
					offset: state.offset + state.limit,
					sdsData: [...sds.sds, ...state.sdsData],
				};
				this.initWebWorker();
				return from(this.callWorkerMethod(nState, params)).pipe(mergeMap(
					(data) => {
						this.client.destroy();
						const success = loadSdsSuccess(data);
						return nState.offset >= sds.totalNumberOfDrivers ? [success] : [success, loadSds()];
					},
				));
			}),
		)),
	);

	@Effect() filterSds = this.actions$.pipe(
		ofType(filterSds),
		concatMap((action) => of(action).pipe(
			withLatestFrom(this.route.queryParams, this.store.select(sdsFeature)),
		)),
		mergeMap(([action, params, state]: [TypedAction<string>, Params, SdsState]) => {
			this.initWebWorker();
			return from(this.callWorkerMethod(state, params)).pipe(
				map((data) => {
					this.client.destroy();
					return loadSdsSuccess(data);
				}),
			);
		}),
	);

	initWebWorker() {
		if (this.workerManager.isBrowserCompatible) {
			this.client = this.workerManager.createClient(SdsWorker);
		} else {
			// if code won't block UI else implement other fallback behaviour
			this.client = this.workerManager.createClient(SdsWorker, true);
		}
	}

	async callWorkerMethod(dat, params) {
		let outcome;
		try {
			await this.client.connect();
			outcome = await this.client.call((w) => w.doSomeWork(dat, params));
		} catch (exception) {
			console.error(exception);
			return null;
		}
		return outcome;
	}

	constructor(
		private route: ActivatedRoute,
		private sdsService: SdsService,
		private store: Store<RootState>,
		private actions$: Actions,
		private workerManager: WorkerManager,
	) { }
}
