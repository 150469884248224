import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from './app-types';
import { OneHour } from '../../utils/time-utils';
import * as appActions from './app.actions';

export const initialState: AppState = {
	timeOptions: {
		options: [],
		selectedOption: {},
	},
	dispatchers: [],
	locations: [],
	filterOptions: {
		assets: [],
		drivers: [],
		locations: [],
	},
	isLoading: false,
	isLoadSuccess: false,
	totalMsRefreshing: 0,
	shouldRefresh: true,
	refreshInterval: false,
	minutesSinceRefresh: 0,
	contextualHelpOpen: false,
	contextualHelpLoading: false,
	contextualHelpHTML: '',
	userId: '',
	displayName: '',
	companyName: '',
	isAdmin: false,
	selectedEntity: {
		label: '',
		value: '',
		entityType: '',
	},
};

export const appReducer = createReducer(
	initialState,
	on(appActions.incrementRefreshMs, (state, { ms }) => ({
		...state,
		totalMsRefreshing: state.totalMsRefreshing + ms,
		shouldRefresh: state.totalMsRefreshing < OneHour,
	})),
	on(appActions.zeroRefreshMs, (state) => ({
		...state,
		totalMsRefreshing: 0,
		shouldRefresh: true,
		minutesSinceRefresh: 0,
		refreshInterval: false,
	})),
	on(appActions.refreshIntervalSet, (state) => ({
		...state,
		refreshInterval: true,
	})),
	on(appActions.setMinutesSinceRefresh, (state) => ({
		...state,
		minutesSinceRefresh: state.minutesSinceRefresh + 1,
	})),
	on(appActions.removeContextualHelp, (state) => ({
		...state,
		contextualHelpOpen: false,
		contextualHelpHTML: '',
	})),
	on(appActions.getContextualHelpSuccess, (state, { payload }) => ({
		...state,
		contextualHelpLoading: false,
		contextualHelpHTML: payload,
	})),
	on(appActions.getContextualHelp, (state, { id, helpType }) => ({
		...state,
		contextualHelpLoading: true,
		contextualHelpOpen: true,
	})),
	on(appActions.getLocationsListSuccess, (state, { locations }) => ({
		...state,
		locations,
		isLoading: false,
		isLoadSuccess: true,
	})),
	on(appActions.getLocationsList, (state) => ({
		...state,
		locations: [],
		isLoading: true,
		isLoadSuccess: false,
	})),
	on(appActions.getDriverOptions, (state) => ({
		...state,
		filterOptions: {
			...state.filterOptions,
			drivers: [],
		},
	})),
	on(appActions.getDriverOptionsSuccess, (state, { drivers }) => ({
		...state,
		filterOptions: {
			...state.filterOptions,
			drivers,
		},
	})),
	on(appActions.getAssetOptions, (state) => ({
		...state,
		filterOptions: {
			...state.filterOptions,
			assets: [],
		},
	})),
	on(appActions.getAssetOptionsSuccesss, (state, { assets }) => ({
		...state,
		filterOptions: {
			...state.filterOptions,
			assets,
		},
	})),
	on(appActions.getLocationOptions, (state) => ({
		...state,
		filterOptions: {
			...state.filterOptions,
			locations: [],
		},
	})),
	on(appActions.getLocationOptionsSuccess, (state, { locations }) => ({
		...state,
		filterOptions: {
			...state.filterOptions,
			locations,
		},
	})),
	on(appActions.getDispatchersListSuccess, (state, { dispatchers }) => ({
		...state,
		dispatchers,
		isLoading: false,
		isLoadSuccess: true,
	})),
	on(appActions.getDispatchersList, (state, { reload }) => ({
		...state,
		dispatchers: [],
		isLoading: true,
		isLoadSuccess: false,
	})),
	on(appActions.setSelectedTimeOption, (state, { days }) => ({
		...state,
		timeOptions: initialState.timeOptions,
	})),
	on(appActions.setTimeOptions, (state) => ({
		...state,
		timeOptions: initialState.timeOptions,
	})),
	on(appActions.setSelectedEntity, (state, { label, value, entityType }) => ({
		...state,
		selectedEntity: { label, value, entityType },
	})),
	on(appActions.getUserDataSuccess, (state, {
		userId,
		displayName,
		companyName,
		isAdmin,
		selectedEntity,
	}) => ({
		...state,
		userId,
		displayName,
		companyName,
		isAdmin,
		selectedEntity,
		isLoading: false,
		isLoadSuccess: true,
	})),
	on(appActions.getUserData, (state) => ({
		...state,
		isLoading: true,
		isLoadSuccess: false,
	})),
	on(appActions.getTimeOptions, (state, { payload }) => ({
		...state,
		timeOptions: payload,
	})),
);

export function AppReducer(state: AppState | undefined, action: Action) {
	return appReducer(state, action);
}
