import { createAction, props } from '@ngrx/store';
import { Dispatcher, Location, SelectedEntity } from './app-types';
import { OptionList } from '../../utils/options';

export const SET_TIME_OPTIONS = '[App] Set Time Options';
export const GET_TIME_OPTIONS = '[App] Get Time Options';
export const GET_USER_DATA = '[App] Get User Data';
export const GET_USER_DATA_SUCCESS = '[App] Get User Data Success';
export const SET_SELECTED_TIME_OPTION = '[Set] SET_SELECTED_TIME_OPTION';

export const GET_CONTEXTUAL_HELP = '[App] Get Contextual Help';
export const GET_CONTEXTUAL_HELP_SUCCESS = '[App] Get Contextual Help Success';
export const REMOVE_CONTEXTUAL_HELP = '[App] Remove Contextual Help';
export const SET_SELECTED_ENTITY = '[App] Set Selected Entity';
export const INCREMENT_REFRESH_MS = '[App] Increment Refresh Ms';
export const ZERO_REFRESH_MS = '[App] Zero Refresh Ms';
export const REFRESH_INTERVAL_SET = '[App] Refresh Interval Set';
export const SET_MINUTES_SINCE_REFRESH = '[App] Set Minutes Since Refresh';

export const GET_LOCATIONS_LIST = '[App] Get Locations List';
export const GET_DISPATCHERS_LIST = '[App] Get Dispatcher List';
export const GET_LOCATIONS_LIST_SUCCESS = '[App Get Location List Success';
export const GET_DISPATCHERS_LIST_SUCCESS = '[App] Get Dispatchers List Success';

export const GET_LOCATIONS_OPTIONS_LIST = '[App] Get Locations Options List';
export const GET_ASSETS_OPTIONS_LIST = '[App] Get Assets Options List';
export const GET_DRIVERS_OPTIONS_LIST = '[App] Get Drivers Options List';

export const GET_LOCATION_OPTIONS_LIST_SUCCESS = '[App Get Locations Options List Success';
export const GET_ASSET_OPTIONS_LIST_SUCCESS = '[App Get Assets List Options Success';
export const GET_DRIVERS_OPTIONS_LIST_SUCCESS = '[App Get Drivers List Options Success';

export const getTimeOptions = createAction(
	GET_TIME_OPTIONS,
	props<{ payload: OptionList }>(),
);

export const setTimeOptions = createAction(SET_TIME_OPTIONS);

export const getUserData = createAction(GET_USER_DATA);

export const getUserDataSuccess = createAction(
	GET_USER_DATA_SUCCESS,
	props<{
		userId: string,
		displayName: string,
		companyName: string,
		isAdmin: boolean
		selectedEntity: SelectedEntity
	}>(),
);

export const setSelectedEntity = createAction(
	SET_SELECTED_ENTITY,
	props<{
		label: string,
		value: string,
		entityType: string
	}>(),
);

export const setSelectedTimeOption = createAction(
	SET_SELECTED_TIME_OPTION,
	props<{ days: string }>(),
);

export const getDispatchersList = createAction(
	GET_DISPATCHERS_LIST,
	props<{ reload: boolean }>(),
);

export const getDispatchersListSuccess = createAction(
	GET_DISPATCHERS_LIST_SUCCESS,
	props<{ dispatchers: Array<Dispatcher>}>(),
);

export const getLocationsList = createAction(GET_LOCATIONS_LIST);

export const getLocationsListSuccess = createAction(
	GET_LOCATIONS_LIST_SUCCESS,
	props<{ locations: Array<Location>}>(),
);

export const getAssetOptions = createAction(GET_ASSETS_OPTIONS_LIST);

export const getAssetOptionsSuccesss = createAction(
	GET_ASSET_OPTIONS_LIST_SUCCESS,
	props<{ assets: Array<any>}>(),
);

export const getDriverOptions = createAction(GET_DRIVERS_OPTIONS_LIST);

export const getDriverOptionsSuccess = createAction(
	GET_DRIVERS_OPTIONS_LIST_SUCCESS,
	props<{ drivers: Array<any>}>(),
);

export const getLocationOptions = createAction(GET_LOCATIONS_OPTIONS_LIST);

export const getLocationOptionsSuccess = createAction(
	GET_LOCATION_OPTIONS_LIST_SUCCESS,
	props<{ locations: Array<any>}>(),
);

export const getContextualHelp = createAction(
	GET_CONTEXTUAL_HELP,
	props<{ id: string, helpType: any }>(),
);

export const getContextualHelpSuccess = createAction(
	GET_CONTEXTUAL_HELP_SUCCESS,
	props<{ payload: any }>(),
);

export const removeContextualHelp = createAction(REMOVE_CONTEXTUAL_HELP);

export const incrementRefreshMs = createAction(
	INCREMENT_REFRESH_MS,
	props<{ ms: number }>(),
);

export const zeroRefreshMs = createAction(
	ZERO_REFRESH_MS,
);

export const refreshIntervalSet = createAction(REFRESH_INTERVAL_SET);

export const setMinutesSinceRefresh = createAction(SET_MINUTES_SINCE_REFRESH);
