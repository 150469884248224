// Sorts the data
import {SELECTED_ENTITY} from "../local-storage.service";
import {throwError} from "rxjs";

const reportsUniqueParams = ['hours', 'sds_reports', 'live-status'];

export const sortArrayData = (data: any[], sortKey: string, direction: 'asc' | 'desc') => {
	const sortedData = data.slice();
	sortedData.sort((rowA, rowB) => {
		if (rowA[sortKey] < rowB[sortKey]) {
			return direction === 'asc' ? -1 : 1;
		}
		if (rowA[sortKey] > rowB[sortKey]) {
			return direction === 'asc' ? 1 : -1;
		}
		return 0;
	});

	return sortedData;
};

export const determineDispatcherOrLocationUtil = (selectedEntity, reports?: string) => {
	// For home page, and live-status api, only location filter will be used
	if (selectedEntity && selectedEntity.entityType === 'dispatcherId' && (reports === 'home' || reports === 'live-status')) {
		return new Error ('Location id not found');
	}
	if(!selectedEntity || !selectedEntity.value || !selectedEntity.value.split(':').length) {
		return new Error('Dispatcher Id or Location Id not found')
	}

	let accountInfo = selectedEntity.value.split(':');
	if (selectedEntity.entityType === 'dispatcherId') {
		if (reportsUniqueParams.includes(reports)) {
			return {
				dispatcher: accountInfo[1]
			};
		}
		return {
			dispatcher_id: accountInfo[1]
		}

	} else {
		if (reportsUniqueParams.includes(reports)) {
			return {
				location: accountInfo[1]
			};
		}
		return {
			location_id: accountInfo[1]
		}
	}
}
