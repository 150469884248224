import {Availability, AvailabilityDetails, TableData} from './availability.types';
import {
	Actions,
	GET_DRIVER_AVAILABILITY,
	GET_DRIVER_AVAILABILITY_FAILED,
	GET_DRIVER_AVAILABILITY_SUCCESS,
	REFRESH_DRIVER_AVAILABILITY,
} from './availability.actions';

export const initialState: AvailabilityDetails = {
	body: {
		report: [],
		offset: 0,
		limit: 500,
		total_number_of_drivers: 0,
		chunk_size: 0,
		service_data: {}
	},
	isLoading: false,
	tableData: [],
	isLoadSuccess: false,
	availability: [],
	availabilityGroupedByDivision: {},
	limit: 500,
	offset: 0,
	total_number_of_drivers: 0,
	isError: false,
};

export function availabilityReducer(state = initialState, action: Actions): AvailabilityDetails {
	switch (action.type) {
	case GET_DRIVER_AVAILABILITY:
		return {
			...state,
			isLoading: true,
			isLoadSuccess: false,
		};
	case REFRESH_DRIVER_AVAILABILITY:
		return state;
	case GET_DRIVER_AVAILABILITY_SUCCESS:
		return action.payload;
	case GET_DRIVER_AVAILABILITY_FAILED:
		return {
			...state,
			isLoading: false,
			isLoadSuccess: false,
			isError: true,
			errorObj: {message: action.payload.message, name: action.payload.name, statusText: action.payload.statusText}
		};
	default:
		return state;
	}
}
