import 'promise-polyfill/src/polyfill';
import 'core-js/es/object';
import {
	AngularWebWorker, bootstrapWorker, OnWorkerInit, Callable,
} from 'angular-web-worker';
import { SdsUtils } from './sds.utils';
/// <reference lib="webworker" />

@AngularWebWorker()
export class SdsWorker {
		@Callable()
	doSomeWork(dat, params): any {
		return new SdsUtils().populateDataModel(dat, params);
	}
}
bootstrapWorker(SdsWorker);
