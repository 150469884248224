import { Action } from '@ngrx/store';
import { StatusData } from './live-status.reducer';

export const GET_LIVE_STATUS = '[Get] GET_LIVE_STATUS';
export const GET_LIVE_STATUS_SUCCESS = '[Set] GET_LIVE_STATUS_SUCCESS';

export class GetLiveStatus implements Action {
	readonly type = GET_LIVE_STATUS;

	constructor() {}
}
export class GetLiveStatusSuccess implements Action {
	readonly type = GET_LIVE_STATUS_SUCCESS;

	constructor() {}
}

export type Actions =
	| GetLiveStatus
	| GetLiveStatusSuccess;
