export const environment = {
	production: true,
	appUrl: 'https://compliance.zonarsystems.net',
	authEnv: 'production',
	prefix: 'production',
	name: 'prod',
	region: 'NA',
	eldxApplicationId: 'd866c783-abe1-4bbc-9f8b-45ad722d9a14',
	// TODO: Setup your application's authentication variables: see
	// https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
	// for instructions on how to do that.
	auth: {
		clientID: 'cx444kTxg505TvdvLGPb7O58YgRTiD9t',
		domain: 'zonar-login.auth0.com',
		audience: 'https://api.zonarsystems.net',
		applicationId: 'f65b5dde-75d1-49c4-af51-81653dcd6f74',
		defaultZonarRole: '2e926326-7fac-4c05-a93c-c7d074354c6f',
		useRefreshTokens: true
	},
	urls: {
		baseApi: `https://api.zonarsystems.net`,
		externalApi: 'https://api.zonarsystems.net/core/v1beta2',
		hosBackendApi: 'https://hos-reports-5uolu6y2aq-uw.a.run.app',
		legacyEntityApi: 'https://legacy-entity-api-5uolu6y2aq-uw.a.run.app',
		eldx: 'https://eldx.zonarsystems.net',
	},
	accountsWithAdmin: ['bos2020','kni1101','knr1101','swizero', 'transmexinc'],
	useHereV3: true,
	datadog: {
		applicationId: 'c345ac2d-a240-464a-bc85-e3696c38716e',
		clientToken: 'pub3b706123189f56e5f8b1e610cf55e6f8',
		site: 'us5.datadoghq.com',
		service: 'compliance-dashboard',
	},
	devCycle: {
		clientId: 'dvc_client_b158c4f8_ed00_488f_a4d9_ec878fad4c6a_44848e0'
	},
	ciDevUrl: undefined
};

export const httpInterceptList =  [
	// core entity api base urls. these are used by the auth module to make calls to the user-api
	environment.urls.baseApi + '/*',
	environment.urls.externalApi + '/*',
	environment.urls.hosBackendApi + '/*',
	environment.urls.legacyEntityApi + '/*',
	`/*`
];
