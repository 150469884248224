<div *ngIf="!hideRefreshBar && minutesSinceRefresh" class="message warning">
  <div class="warning-icon"><img src="./assets/images/warning-icon.png"></div>
  <div class="message-area">
    <div class="text-area">
      <header>Data is out of sync</header>
      <span>It has been {{minutesSinceRefresh | humanizeDuration: 'min'}} since data was last updated</span>
    </div>
    <div class="button-area">
      <button mat-button color="accent" class='refresh' (click)="startRefresh()">
        <div>
          <span>Update Data Now</span>
        </div>
      </button>
    </div>
  </div>
</div>
