import 'promise-polyfill/src/polyfill';
import 'core-js/es/object';
import {
	AngularWebWorker, bootstrapWorker, OnWorkerInit, Callable,
} from 'angular-web-worker';
import { OdometerUtils } from './odometer.utils';
/// <reference lib="webworker" />

@AngularWebWorker()
export class OdometerWorker {
		@Callable()
	doSomeWork(dat, params): any {
		return new OdometerUtils().populateDataModel(dat, params);
	}
}
bootstrapWorker(OdometerWorker);
