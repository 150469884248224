import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { PermissionsService } from "@zonar-ui/auth";
import { iif, Observable, of } from "rxjs";
import { catchError, finalize, map, switchMap, take } from "rxjs/operators";
import { RootState } from "./app.module";
import { ACTIVE_ACCOUNT_PARAM, LocalStorageService, SELECTED_ENTITY } from "./local-storage.service";
import { getUserDataSuccess } from "./state/app/app.actions";
import { AppService } from "./state/app/app.service";

export interface AppRouteData {
    activeAccountSelected: boolean,
    user: any,
    dispatcher: any
}

@Injectable({
    providedIn: 'root'
})
export class AppResolver implements Resolve<Observable<AppRouteData>> {

    constructor(private appService: AppService,
        private localStorageService: LocalStorageService,
        private permissionsService: PermissionsService,
        private store: Store<RootState>,
        private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppRouteData> {
        const activeParam = route.queryParamMap.get('active');
        // If active query is not present and existing active is not in localstorage, 
        // then no active parameter message will be shown
        if (!activeParam && !this.localStorageService.exists(ACTIVE_ACCOUNT_PARAM)) {
            return of({
                activeAccountSelected: false,
                user: null,
                dispatcher: null
            });
        }
        // This will be used to display progress bar on the app-root.component.ts
        this.appService.isAppDataLoading$.next(true);
        const lastAcct = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);
        if (activeParam) {
            this.localStorageService.set(ACTIVE_ACCOUNT_PARAM, activeParam);
        }
        return this.permissionsService.users$.pipe(
            take(1),
            map(users => {
                if (!users || users.length == 0) {
                    throw new Error('No users found');
                }
                return users[0];
            }),
            switchMap(user => iif(() => this.appService.isZonarAdmin(user),
                // If user zonar admin
                of({ user, dispatcher: null }),
                // If user is not zonar admin
                this.appService.getDispatcherIdByEmail(user.email).pipe(map(id => {
                    return { user, dispatcher: id };
                })))),
            map(({ user, dispatcher }) => {
                this.setUserDetails(user, dispatcher, lastAcct);
                return {
                    activeAccountSelected: true,
                    user: user,
                    dispatcher: dispatcher,
                };
            }),
            catchError(err => {
                // If there is any error route to error page
                this.router.navigate(['error']);
                return of({
                    activeAccountSelected: true,
                    user: null,
                    dispatcher: null,
                });
            }),
            finalize(() => this.appService.isAppDataLoading$.next(false)),
        )
    }

    public setUserDetails(user: any, dispatcher: any, lastAcct: string) {
        const selectedEntity = this.localStorageService.get(SELECTED_ENTITY) || {
            label: ``,
            value: '',
            type: '',
            entityType: '',
        }
        const activeAcct = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);
        if (dispatcher && dispatcher.id && (lastAcct !== activeAcct || !selectedEntity.value)) {
            selectedEntity.value = `${activeAcct}:${dispatcher.id}`;
            selectedEntity.label = `${user.firstName} ${user.lastName}`;
            selectedEntity.type = 'dispatcherId';
            selectedEntity.entityType = 'dispatcherId';
            this.localStorageService.set(SELECTED_ENTITY, selectedEntity);
        } else  if(selectedEntity.value && lastAcct !== activeAcct) {
            // Clear previous selection, let user choose new entity from location/dispatcher on new account selection.
            this.localStorageService.remove(SELECTED_ENTITY);
            selectedEntity.label = '';
            selectedEntity.value = '';
            selectedEntity.type = '';
            selectedEntity.entityType = '';
        }
        const data = {
            userId: user.id,
            displayName: selectedEntity.label,
            companyName: activeAcct,
            divisions: [],
            isAdmin: this.appService.isZonarAdmin(user) || this.appService.isCustomerAdmin(activeAcct),
            selectedEntity,
        }
        this.store.dispatch(getUserDataSuccess(data))
    }
}