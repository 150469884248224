import {
	Actions,
	GET_LIVE_STATUS,
	GET_LIVE_STATUS_SUCCESS,
} from './live-status.actions';
import {HosViolationsLiveStatusState} from "../../reports/hos-violations/hos-violation-types";

export interface LiveStatusEntry {
	driverId: string;
	dutyStatus: string;
	lat: number;
	long: number;
	heading: number;
	fleet: string;
	driverFullName: string;
}


export interface FullLiveStatus {
	driverId: string;
	dutyStatus: string;
	lat: number;
	long: number;
	heading: string; // Note, not number, unlike what is returned from the API
	fleet: string;
	driverFullName: string;
	driverViolationUrl?: string;
	last_location?: string;
}

export interface KPIData {
	type: string;
	value: number;
}

export interface StatusData {
	liveStatusMapData: FullLiveStatus[];
	liveStatusKPIData?: KPIData[];
}

export const initialState: State = Object.freeze({
	isLoading: false,
	isLoadSuccess: false,

});

export interface State {
	isLoading: boolean;
	isLoadSuccess: boolean;
}

export function loadingStateReducer(state, action: Actions): State {
	switch (action.type) {
	case GET_LIVE_STATUS:
		return {
			isLoading: true,
			isLoadSuccess: false,
		};
	case GET_LIVE_STATUS_SUCCESS:
		return {
			isLoading: false,
			isLoadSuccess: true,
		};
	default:
		return state;
	}
}
export interface LiveStatusModel {
	liveStatus: LiveStatusEntry[];
	violationLiveStatusData: HosViolationsLiveStatusState;
}
