import { createAction, props } from '@ngrx/store';
import {HoursHttpErrorResponse, HoursState} from './hours.types';

export const loadHours = createAction(
	'[Hours] Load Hours',
);

export const loadHoursFilters = createAction(
	'[Hours] Load Hours Filters',
);

export const loadHoursFiltersSuccess = createAction(
	'[Hours] Load Hours Filters Success',
	props<{filters: any}>(),
);

export const loadHoursRange = createAction(
	'[Hours] Load Hours Range',
);

export const filterHours = createAction(
	'[Hours] Filter Hours',
);

export const loadHoursSuccess = createAction(
	'[Hours] Load Hours Success',
	props<HoursState>(),
);

export const loadHoursFailure = createAction(
	'[Hours] Load Hours Failure',
	props<{ error: { message: any; statusText: any; name: any; }; }>(),
);
