<div>
	<div *ngIf="showMetricCard" class="kpi-component">
		<div class="kpi-item">
			<div class="value">{{kpiData.foundMalfunctions}}</div>
			<div class="label">Total Malfunctions</div>
		</div>
		<div *ngIf="kpiData.topMalfunctionType !== 'None'" class="kpi-item">
			<div class="value">{{kpiData.topMalfunctionType}}</div>
			<div class="label">Top Malfunction Type</div>
		</div>
		<div *ngIf="kpiData.affectedAssets !== undefined" class="kpi-item">
			<div class="value">
				<ng-container *ngIf="kpiData.affectedAssets >= 1">
					<div class="value">{{kpiData.affectedAssets}}</div>
					<span class="percentage">%</span>
				</ng-container>
				<ng-container *ngIf="kpiData.affectedAssets < 1">
					<div class="value">
						< 1</div>
							<span class="percentage">%</span>
				</ng-container>
			</div>
			<div class="label">of assets impacted</div>
		</div>
		<div *ngIf="kpiData.affectedDrivers !== undefined" class="kpi-item">
			<div class="value">
				<ng-container *ngIf="kpiData.affectedDrivers >= 1">
					<div class="value">{{kpiData.affectedDrivers}}</div>
					<span class="percentage">%</span>
				</ng-container>
				<ng-container *ngIf="kpiData.affectedDrivers < 1">
					<div class="value">
						< 1</div>
							<span class="percentage">%</span>
				</ng-container>
			</div>
			<div class="label">of drivers Impacted</div>
		</div>
	</div>
	<div *ngIf="kpiData && kpiData.chartSeriesData"
		[ngClass]="showMetricCard ? 'cd-card cd-card-style cd-details-card': ''">
		<div *ngIf="isLoading$ | async; else chartTemplate">
			<mat-spinner diameter="300" color="accent" strokeWidth="4" [style]="{height: '450px', margin:'0 auto'}"
				class="chart-spinner">
			</mat-spinner>
		</div>
		<ng-template #chartTemplate>
			<div *ngIf="errMessage$ | async; else noError" class="error-message">
				<zui-hos-error-container
						[showError]="!!errMessage$"
						[errorMessage]="errMessage$ | async"
						[reports]="report" >
				</zui-hos-error-container>
			</div>
			<ng-template #noError>
				<header class="topic-card-header selenium-malfunctions-card-header">
					<span class="date-range-stub" *ngIf="showViewDetailLink">{{kpiCardTitle}}</span>
					<h2>Assets impacted by Malfunction Type</h2>
				</header>
				<div class="highcart-container">
					<highchart-component [chartData]="kpiData.chartSeriesData && kpiData.chartSeriesData.chart"
						class="highchart" [showLegend]="false"></highchart-component>
				</div>
				<div *ngIf="showViewDetailLink">
					<div class="topic-card-actions">
						<div>
							<a [routerLink]="routerLink" queryParamsHandling="merge">
								<strong>View Details</strong>
							</a>
						</div>
					</div>
				</div>
			</ng-template>
		</ng-template>
	</div>
</div>
