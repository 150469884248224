<div class="data-table-container cd-table-card">
	<table
		(matSortChange)="this.activeSort = $event;"
		matSort mat-table [dataSource]="dataSource" class="mat-elevation-z0" cssClassFriendlyName='hos-table-1'
		matSortStart="desc">
		<ng-container *ngFor="let column of displayedColumns; let i = index;" matColumnDef="{{column}}">
			<ng-container *ngIf="column === 'violationDuration'; else defaultHeader" >
				<th mat-header-cell *matHeaderCellDef mat-sort-header="durationSecs"> {{tableHeaders[i]}}</th>
			</ng-container>
			<ng-template #defaultHeader>
				<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isSortingDisabled()"> {{tableHeaders[i]}}</th>
			</ng-template>
			<td mat-cell *matCellDef="let row" [class.isSkeleton]="showSkeleton">
				<ng-container *ngIf="showSkeleton">
					<span class="skLoader">
						<ngx-skeleton-loader
								count="5"
								[theme]="skeletonTheme()">
						</ngx-skeleton-loader>
					</span>
				</ng-container>
				<ng-container *ngIf="!showSkeleton">

				<span *ngIf="column === 'start_odo_date' || column === 'end_odo_date'" class="row-item-data">{{row[column] | date: 'medium'}}</span>
				<span *ngIf="column === 'startTime'">{{(row[column] | dateTimeTimezone : row.timezone) || 'Unknown'}}</span>

				<span *ngIf="column === 'start_epoch'">{{(row[column] | dateTimeTimezone : row.timezone) || 'Unknown'}}</span>
				<span *ngIf="column === 'end_epoch'">{{(row[column] | dateTimeTimezone : row.timezone) || 'Unknown'}}</span>

				<span *ngIf="column === 'total_time'">
					{{row[column] | humanizeDuration: 's'}}
				</span>

				<span *ngIf="column === 'endTime'">
					<span *ngIf="row[column]">Cleared -
					 {{(row[column] | dateTimeTimezone: row.timezone) || 'date not known'}}</span>
					<span *ngIf="!row[column]">Active</span>
				</span>

				<span
					*ngIf="column === 'began_driving_in_violation'">{{(row[column] | date: 'M/d/yy H:mm z') || 'Unknown'}}
				</span>

				<span *ngIf="column === 'duration'"> {{ (row[column] | secondsToHoursMinutes) || 'Unknown'}} </span>

				<span *ngIf="column === 'link'">
					<a routerLink="/hos-violations/driver/{{row[column].driverId}}" routerLinkActive="active"
						queryParamsHandling="merge">
						{{row[column].display}}
					</a>
				</span>

				<span *ngIf="column === 'sparklineData'">
					<highchart-component [chartData]="row[column].chart" [showDeleteFilter]="false"
						[chartId]="'sparkline'">
					</highchart-component>
				</span>

				<span *ngIf="column === 'change'">
					<span *ngIf="row[column] > 0">
						<span class="table-arrow table-arrow-upward"></span>
					</span>
					<span *ngIf="row[column] < 0">
						<span class="table-arrow table-arrow-downward"></span>
					</span>
				</span>

				<span *ngIf="column === 'distance'">
					{{ row[column] && row[column] > 0 && row[column] < MAX_LIKELY_SDS_DISTANCE ?
					(row[column] | metersToMiles | number:'1.1-1') : 'Unknown'}}
				</span>

				<span *ngIf="column === 'divisionName' && !row['malfunctionPeriod'] && row['divisionName'] !== ''">
					<a (click)="divisionClicked(row)" class="clickable">
						{{row[column] || 'Unknown'}}
					</a>
				</span>
                <span *ngIf="column == 'onHours' || column=='offHours' || column=='sbHours' || column=='driveHours' || column=='onDriveHours'">
                    {{row[column]}}
                </span>

				<span *ngIf="column !== 'change' &&
				column !== 'sparklineData' &&
				column !== 'start_epoch' &&
				column !== 'end_epoch' &&
				column !== 'start_odo_date' &&
				column !== 'end_odo_date' &&
				column !== 'divisionName' &&
				column !== 'link' &&
				column !== 'malfunction_driver_name' &&
				column !== 'displayMalfunctionType' &&
				column !== 'endTime' &&
				column !== 'duration' &&
				column !== 'activeMalfunction' &&
				column !== 'began_driving_in_violation' &&
				column !== 'startTime' &&
				column !== 'total_time' &&
				column !== 'malfunctionPeriod' &&
				column !== 'distance' &&
				row['driver_name'] !== '' &&
				row['divisionName'] !== '' &&
				column !== 'onHours' &&
				column !== 'offHours' &&
				column !== 'sbHours' &&
				column !== 'driveHours' &&
				column !== 'onDriveHours'
				">{{row[column] || 'Unknown'}}
				</span>
				</ng-container>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
	<mat-error *ngIf="tableRows && tableRows.length < 1 && isEntitySelected && !showSkeleton" class="error-container">
		<mat-card class="error-card">
			<div class="title">No results found</div>
			Please modify your search and try again.
		</mat-card>
	</mat-error>
	<div>
		<mat-paginator [ngStyle]="{display: (this.tableRows && this.tableRows.length >= 10) || showSkeleton ? 'block' : 'none'}"
	   		[pageSizeOptions]="showSkeleton ? 0 : pageSizeOptions"
			[pageSize]="pageSize" (page)="pageSizeSelected($event)" class="tablePaginator">
		</mat-paginator>
	</div>
</div>
