import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseService } from '../../api/base-service';
import { ApiConfiguration } from '../../api/api-configuration';
import { RootState } from '../../app.module';
import { OptionList, OptionValue } from '../../utils/options';
import { DAY_RANGES } from '../../components/constants/dayRanges';
import { TwoMinutes } from '../../utils/time-utils';
import { environment } from 'environments/environment';

export const REFRESH_TIME_IN_MS = 120000;

@Injectable({
	providedIn: 'root',
})
export class AppService extends BaseService {
	public interval: any;
	public isAppDataLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		public store: Store<RootState>,
		config: ApiConfiguration,
		http?: HttpClient,
	) {
		super(config, http);
	}

	loadDispatchers(): Observable<Object> {
		return this.http.get(`${this.rootUrl}/api/v2/dispatchers`, {});
	}

	loadLocations(): Observable<Object> {
		return this.http.get(`${this.rootUrl}/api/v2/locationList`, {});
	}

	loadAssetOptions(): Observable<Object> {
		return this.http.get(`${this.rootUrl}/api/v2/assetOptions`, {});
	}

	loadLocationOptions(): Observable<Object> {
		return this.http.get(`${this.rootUrl}/api/v2/locationOptions`, {});
	}

	loadDriverOptions(): Observable<Object> {
		return this.http.get(`${this.rootUrl}/api/v2/driverOptions`, {});
	}

	loadUserData(): Observable<Object> {
		return this.http.get(`${this.rootUrl}/api/v1/userDetails`, {});
	}

	getContextualHelp(id: string, helpType: string): Observable<Object> {
		return this.http.get(`${this.rootUrl}/api/v2/contextual-help`, {
			params: { id, helpType },
		});
	}

	refreshData(actions = []): void {
		const self = this;
		clearInterval(this.interval);
		this.interval = setInterval(() => {
			_.forEach(actions, (action) => {
				self.store.dispatch(action);
			});
		}, TwoMinutes);
	}

	refreshDataOnce(actions = []): void {
		_.forEach(actions, (action) => {
			this.store.dispatch(action);
		});
	}

	stopRefreshing(): void {
		clearInterval(this.interval);
	}

	// TODO: to get options from shared service
	getTimeOptions(timeOption = 'day', selectedOption: OptionValue | {}): OptionList {
		const timeFilter = {
			selectedOption: {},
			options: [],
		};
		if (timeOption === 'day') {
			timeFilter.selectedOption = selectedOption || DAY_RANGES[5].value; // defaults to 180 days
			timeFilter.options = DAY_RANGES;
		}
		return timeFilter;
	}

	public isZonarAdmin(user: any): boolean {
		return user.email.endsWith('@zonarsystems.com');
	}

	public isCustomerAdmin(account: string): boolean {
		return environment.accountsWithAdmin.includes(account);
	}

	getDispatcherIdByEmail(email: string): Observable<{id: any}> {
		// Entity API get dispatcher is not working due to zonar owner id is not attaching on header
		// TODO: figure out why the owner id is not attached on header by the interceptor
		// const url = `${environment.urls.externalApi}/dispatchers`;
		// return this.http.get(url, { params: { userId } })
		// 	.pipe(map(dispatchers => dispatchers[0]));
		return this.http.get<{id: any}>(`${this.rootUrl}/api/v2/legacyDispatcherId?email=${email}`);
	}
}
