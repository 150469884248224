import {
	Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

const _ = require('lodash');

@Component({
	selector: 'data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss'],
	animations: [],
})
export class DataTableComponent implements OnInit, OnChanges {
	@Input() isEntitySelected: boolean = true;

	@Input() isLocationSelected: boolean = true;

	@Input() tableData: any = { headers: null, displayedColumns: null, rows: null, unsorted: null };

	@Input() showLoading: boolean = true;

	@Input() pageSizeOptions;

	@Input() malfunctionsTableDialogData;

	@Input() showSkeleton: boolean = false;

	@Input() reportType: string = '';

	@Output() divisionClick: EventEmitter<any> = new EventEmitter<any>();

	@Output() malfunctionDriverClicked: EventEmitter<any> = new EventEmitter<any>();

	@Output() driverClick: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild(MatSort, { static: true }) sort: MatSort;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	displayedColumns: string[];

	tableHeaders: string[];

	activeSort: {};

	dataSource;

	tableRows;

	unsorted;

	pageSize;

	MAX_LIKELY_SDS_DISTANCE = 1000 * 1609.344;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) { }

	ngOnInit() {
		this.activatedRoute.queryParams
			.subscribe((params) => {
				this.getPageSize(params);
			});
	}

	ngOnChanges(changes) {
		if (this.tableData && changes.tableData) {
			this.tableRows = this.tableData.rows;
			this.tableHeaders = this.tableData.headers;
			this.displayedColumns = this.tableData.displayedColumns;
			this.unsorted = this.tableData.unsorted;
			const isAvailability = (
				this.displayedColumns &&
				this.displayedColumns.indexOf('avail_on') > -1
				&& this.displayedColumns.indexOf('avail_d') > -1
				&& this.displayedColumns.indexOf('duty_period') > -1
			);

			this.dataSource = new MatTableDataSource(this.tableRows);

			if (this.tableRows && this.tableRows.length >= 10) {
				this.dataSource.paginator = this.paginator;
			}
			this.dataSource.sort = this.sort;
			if (!this.activeSort) {
				if (this.displayedColumns && this.displayedColumns.includes('began_driving_in_violation')) {
					this.sort.sort(<MatSortable>({ id: 'began_driving_in_violation', start: 'desc' }));
				} else if (this.displayedColumns && this.displayedColumns.includes('total')) {
					this.sort.sort(<MatSortable>({ id: 'total', start: 'desc' }));
				} else if (this.displayedColumns
					&& (this.displayedColumns.includes('activeMalfunction') && !this.displayedColumns.includes('endTime'))) {
					this.sort.sort(<MatSortable>({ id: 'activeMalfunction', start: 'desc' }));
				} else if (this.displayedColumns && !isAvailability) {
					this.sort.sort(<MatSortable>({ id: this.displayedColumns[0], start: 'desc' }));
				}
			}
			this.dataSource.sortingDataAccessor = (item, property) => {
				const itemProperty = item[property];

				switch (property) {
				case 'endTime':
					return itemProperty ? Date.parse(itemProperty) : Date.now();
				case 'currentDriver':
					return itemProperty ? Date.parse(item.startTime) : Date.now();
				default: return itemProperty;
				}
			};
		}
	}

	showDialog(data): void {
		this.driverClick.emit(data);
	}

	getDriverRouterLink(row) {
		return ['driver', row.id];
	}

	isSortingDisabled() {
		return (this.unsorted);
	}

	divisionClicked(division) {
		this.divisionClick.emit({ value: division.location });
	}

	getPageSize(params) {
		if (params.pageSize) {
			this.pageSize = params.pageSize;
		} else {
			this.pageSize = (this.pageSizeOptions && this.pageSizeOptions[0]) ? this.pageSizeOptions[0] : 10;
		}
	}

	pageSizeSelected(event) {
		this.router.navigate([],
			{
				relativeTo: this.activatedRoute,
				queryParams: { pageSize: event.pageSize },
				queryParamsHandling: 'merge',
			});
	}

	skeletonTheme () {
		return 	{
			width:'100%',
			padding:'2px auto',
			height: '40px',
			'background-color': this.reportType === 'hours' ? '#E3E3E3' : this.getBackgroundColor(),
			'margin-bottom': 'unset',
			border: this.reportType === 'hours' ?' 2px solid white' : '1px solid white' }
	}


	getBackgroundColor() {
		return this.isEntitySelected ? '#E3E3E3' : '#F5F5F5'
	}

}
