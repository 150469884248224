import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Params } from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import { ApiConfiguration } from 'app/api/api-configuration';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'app/api/base-service';
import {environment} from '../../../environments/environment';
import {LocalStorageService, SELECTED_ENTITY} from '../../local-storage.service';
import {determineDispatcherOrLocationUtil} from "../../utils/report-utils";

@Injectable()
export class HoursService extends BaseService {
	constructor(config: ApiConfiguration,
				http: HttpClient,
				private localStorageService: LocalStorageService) { super(config, http); }

	public getReportDetailsData(queryParams: Params): Observable<any> {
		const now = moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
		const params: { [index: string]: string } = {
			beginDate: queryParams.beginDate || now.clone().subtract(14, 'days').startOf('day').format(),
			endDate: queryParams.endDate || now.clone().add(1, 'hour').format(),
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			limit: queryParams.limit || 25,
			offset: queryParams.offset || 0,
		};
		if (queryParams.driverId && queryParams.driverId !== 'all') {
			params.driverids = queryParams.driverId;
		}
		if (queryParams.driverHomeLocation && !this.isAllSelected(queryParams, 'driverHomeLocation')) {
			params.locations = queryParams.driverHomeLocation;
		}
		if (queryParams.tagid && !this.isAllSelected(queryParams, 'tagid')) {
			params.tagids = queryParams.tagid;
		}
		if (queryParams.exsid && !this.isAllSelected(queryParams, 'exsid')) {
			params.exsids = queryParams.exsid;
		}
		if (queryParams && queryParams.distanceFilter) {
			params.distanceFilter = queryParams.distanceFilter;
		}
		return this.http.get<any>(`${this.rootUrl}/api/v2/hours`, { params });
	}

	public getHoursReport(queryParams: any): Observable<any> {
		let params = _.clone(queryParams);
		params = {...params, limit: 100};

		// reset the start date and end date to midnight
		let start_date = params.start_date.split('T')[0];
		params.start_date = moment(start_date).startOf('date').format();

		params.account_code = this.localStorageService.get('activeAccount');
		let url = `${environment.urls.hosBackendApi}/api/v1/driver-hours`;
		return this.http.get(url, {params, observe: 'response'});
	}

	private isAllSelected(queryParams: Params, field: string): boolean {
		console.log('query param filed::::::::', queryParams[field]);
		return queryParams[field] && (Array.isArray(queryParams[field]) ? queryParams[field].length == 1 && queryParams[field][0] === 'all':
			queryParams[field] === 'all');
	}

}
