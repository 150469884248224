<div>
    <div class='view-content'>
		<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading" ></mat-progress-bar>
		<report-title title="Malfunctions" helpUrl="https://support.zonarsystems.net/hc/en-us/articles/360052899871-Malfunctions-Report"
			[showToggle]="true" [toggleChecked]="groupByAssetFilter" (toggleChange)="groupByAsset($event)"></report-title>
		<div class="zui-filters-container">
			<zui-hos-filters-container
				[filterList]="dataForFilters" [queryParamKeys]="queryParams" (filtersChange)="filtersChanged($event)" title="">
			</zui-hos-filters-container>
		</div>
		<malfunctions-kpi [showMetricCard]="true" [queryParams]="params" [showViewDetailLink]="false"></malfunctions-kpi>
        <div class='malsbydriver'>
            <div class='filtersHeader'>
                <span class='tableTitle'>{{tableTitle}}</span>
				<csv-export [queryParams]="params"
							[dataService]="this.malfunctionsService"
							[dataType]="'malfunctions'"
				></csv-export>
            </div>
			<zui-hos-table
				#malfunctionsTable
				[columns]="tableColumns"
				[dataSource]="tableDataSource">
			</zui-hos-table>
        </div>
    </div>
</div>
<!-- Custom Cell templates for table -->
<ng-template #multipleTextTemplate let-row="row" let-column="column">
	<!--TODO: figure out how to make this a template and pass into display type to avoid duplicate code-->
	<ng-container *ngIf="column.cell(row) && column.cell(row).includes('Multiple'); else default">
		<a class="link" (click)="openModalToShowList(row, column)">{{column.cell(row)}}</a>
	</ng-container>
	<ng-template #default>
		{{column.cell(row)}}
	</ng-template>
</ng-template>
<ng-template #currentDriver let-row="row" let-column="column">
	<div class="active-mal">
		<img *ngIf="row.activeMalfunction" src="/assets/images/active-mal-icon.svg">
		<span>{{row['currentDriver'] || 'Unknown'}}</span>
	</div>
</ng-template>
<ng-template #displayMalfunctionType let-row="row" let-column="column">
	<ng-container *ngIf="column.cell(row) && column.cell(row).includes('Multiple'); else default">
		<a class="link" (click)="openModalToShowList(row, column)">{{column.cell(row)}}</a>
	</ng-container>
	<ng-template #default>
		<div class="display-malfunction-type">
			{{row['displayMalfunctionType'] || row['displayTypes'] || 'Unknown'}}
			<mat-icon (click)="openContextualHelp(row['displayMalfunctionType'] || row['displayTypes'])" class="material-icons-outlined">info_outline</mat-icon>
		</div>
	</ng-template>
</ng-template>
