<div class='dispatcher-contain'>
    <div *ngIf="locations && locations.length" class="dispatcher-list">
        <div class="autocomplete-wrapper dispatcher-autocomplete" [ngClass]="{'showCritical': isCritical$ | async}">
            <span class="toggle_id" [ngStyle]="{'opacity':'1'}">Location</span>
            <zui-searchable-dropdown
                    label="LOCATION*"
                    placeholder="'Enter Location Name'"
                    [options]="filteredResults"
                    [z_formGroup]="locationFormGroup"
                    [isMultiple]="false"
                    [selectOptionsControlName]="'listedValue'"
                    [searchFormControlName]="'locationId'"
                    classIdentifier="dropdown-select-panel"
            ></zui-searchable-dropdown>
        </div>
    </div>
</div>
