import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from 'app/pipes/pipes.module';
import { DialogModule } from '../dialog/dialog.module';
import { DialogComponent } from '../dialog/dialog.component';
import { DirectivesModule } from '../../directives/directives.module';
import { HighchartModule } from '../highchart/highchart.module';
import { DataTableComponent } from './data-table.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
	imports: [
		MatTableModule,
		HighchartModule,
		CommonModule,
		RouterModule,
		MomentModule,
		MatSortModule,
		MatPaginatorModule,
		DialogModule,
		MatTooltipModule,
		MatDialogModule,
		DirectivesModule,
		PipesModule,
		NgxSkeletonLoaderModule,
		MatCardModule,
		MatFormFieldModule,
	],
	exports: [
		DataTableComponent,
	],
	providers: [
	],
	declarations: [
		DataTableComponent,
	],
	entryComponents: [DialogComponent],
})
export class DataTableModule { }
