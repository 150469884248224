import {
	Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output,
} from '@angular/core';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import * as _ from 'lodash';
import { ColumnChart, LineChart, SparklineChart } from './chart-types';

const Highcharts = require('highcharts');

@Component({
	selector: 'highchart-component',
	templateUrl: './highchart.component.html',
	styleUrls: ['./highchart.component.scss'],
	providers: [],
})
export class HighchartComponent implements OnInit, OnChanges {
	@Input() chartData;

	@Input() showDeleteFilter: boolean;

	@Input() chartId: string;

	@Input() showLegend: boolean = true;

	@Output() deleteFilter = new EventEmitter<{}>();

	// internal variables to keep chart state
	private highchartChart;

	constructor(private elementRef: ElementRef) {
		(<any>window).moment = moment;
		(<any>window).momentTimezone = momentTimezone;

		this.chartData = {
			type: '',
			title: {
				xAxis: '',
				yAxis: '',
			},
			series: [],
		};
		this.showDeleteFilter = false;
		this.chartId = '';
		this.highchartChart = { series: [] };
	}

	ngOnInit() {
		this.setTheme();
	}

	ngOnChanges() {
		this.drawChart();
	}

	private drawChart() {
		this.highchartChart = {
			series: [],
		};
		const chartConfig = this.getChartConfig(this.chartData);
		this.highchartChart = new Highcharts.chart(this.elementRef.nativeElement.firstElementChild, chartConfig);
		this.handleNoData(chartConfig, this.highchartChart);
	}

	private getChartConfig(chartData) {
		if (_.get(this, 'chartData.type')) {
			const charts = {
				line: this.getLineChartConfig,
				column: this.getColumnChartConfig,
				sparkline: this.getSparklineChartConfig,
			};

			return charts[this.chartData.type](chartData) || {};
		}
		return {
			series: chartData,
			chart: {
				type: 'column',
				plotBorderWidth: 1,
				name: 'Malfunctions',
			},
			xAxis: {
				minPadding: 0,
				maxPadding: 0,
				categories: ['', 'Power', 'Data Transfer', 'Engine Sync', 'Position', 'Data Recording', 'Timing'],
				gridLineWidth: 0,
				labels: { autoRotation: 0 },
			},
			yAxis: {
				allowDecimals: false,
				gridLineWidth: 1,
				tickLength: 7,
				tickWidth: 1,
				title: { text: undefined },
			},
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					pointStart: 1,
					pointPadding: 0,
					groupPadding: 0.15,
					events: {
						legendItemClick: (): boolean => false,
					},
				},
			},
			credits: {
				enabled: false,
			},
			borderWidth: 1,
			legend: {
				enabled: this.showLegend,
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				symbolRadius: 0,
				itemStyle: {
					fontWeight: 400,
					cursor: 'auto',
				},
				margin: 30,
			},
		};
	}

	private getSparklineChartConfig(chart: SparklineChart) {
		return {
			chart: {
				type: 'area',
				height: 75,
				width: 300,
				borderColor: '#D8D9D8',
			},
			credits: false,
			title: {
				text: '',
			},
			time: {
				timezone: 'America/New_York',
				useUTC: true,
			},
			xAxis: {
				title: {
					text: '',
				},
				labels: {
					enabled: false,
				},
				type: 'datetime',
				dateTimeLabelFormats: {
					month: '%B',
				},
				gridLineWidth: 0,
				tickLength: 0,
			},
			yAxis: {
				title: {
					text: '',
				},
				labels: false,
			},
			tooltip: {
				formatter() {
					return `${this.y} Violations<br/>${Highcharts.dateFormat('%m/%d/%y', this.x)}`;
				},
			},
			plotOptions: {
				series: {
					marker: {
						enabled: false,
					},
					showInLegend: false,
					label: {
						connectorAllowed: false,
					},
					pointStart: 1,
				},
			},
			series: chart.series,
		};
	}

	private getLineChartConfig(chart: LineChart) {
		return {
			time: {
				timezoneOffset: new Date().getTimezoneOffset(),
				useUtc: false,
			},
			chart: {
				type: 'line',
				plotBorderWidth: 1,
			},
			xAxis: {
				title: {
					text: '',
				},
				type: 'datetime',
				dateTimeLabelFormats: {
					month: '%B',
				},
				gridLineWidth: 1,
				startOnTick: true,
				endOnTick: true,
			},
			yAxis: {
				allowDecimals: false,
				title: {
					text: '',
				},
				gridLineWidth: 1,
				plotLines: [{
					value: chart.goal,
					color: '#ED1C24',
					width: 2,
					zIndex: 1000,
				}],
				tickLength: 7,
				tickWidth: 1,
			},
			legend: {
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				enabled: this.showLegend,
				useHTML: true,
				squareSymbol: true,
				symbolWidth: 8,
				symbolHeight: 10,
				labelFormatter() { // refers to a function inside highcharts, not highcharts.component
					let label = this.name;
					if (this.userOptions.canDeleteSeries) {
						label += '<span class="fa ui-icon-cancel highchart-delete"></span>';
					}
					return label;
				},
			},
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					pointStart: 1,
					pointPadding: 0,
					groupPadding: 0,
				},
			},
			series: chart.series,
			responsive: {
				rules: [{
					condition: {
						maxWidth: 9999,
					},
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom',
						},
					},
				}],
			},
			credits: {
				enabled: false,
			},
			borderWidth: 1,
		};
	}

	private getColumnChartConfig(chart: ColumnChart) {
		const goalLine = {
			value: chart.goal,
			color: '#ED1C24',
			width: 2,
			zIndex: 1000,
		};
		return {
			chart: {
				type: 'line',
				plotBorderWidth: 1,
			},
			title: {
				text: '',
			},
			xAxis: {
				minPadding: 0,
				maxPadding: 0,
				title: {
					text: chart.title.xAxis,
				},
				type: 'datetime',
				dateTimeLabelFormats: {
					month: '%B',
				},
				gridLineWidth: 0,
			},
			yAxis: {
				allowDecimals: false,
				title: {
					text: chart.title.yAxis,
				},
				gridLineWidth: 1,
				plotLines: [...(chart.goal ? [goalLine] : [])],
				tickLength: 7,
				tickWidth: 1,
			},
			legend: {
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				enabled: this.showLegend,
				useHTML: true,
				squareSymbol: true,
				symbolWidth: 8,
				symbolHeight: 10,
				labelFormatter() { // refers to a function inside highcharts, not highcharts.component
					let label = this.name;
					if (this.userOptions.canDeleteSeries) {
						label += '<span class="fa ui-icon-cancel highchart-delete"></span>';
					}
					return label;
				},
			},
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					pointStart: 1,
					pointPadding: 0,
					groupPadding: 0,
				},
			},
			series: chart.series,
			responsive: {
				rules: [{
					condition: {
						maxWidth: 9999,
					},
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom',
						},
					},
				}],
			},
			credits: {
				enabled: false,
			},
			borderWidth: 1,
		};
	}

	private handleNoData(chartConfig, highchartChart) {
		if (chartConfig.series) {
			const seriesData = chartConfig.series[0];
			if (seriesData && (!seriesData.data || seriesData.data.length < 1)) { // check if series is empty
				highchartChart.renderer
					.text('No data available for your query', 0, 20)
					.css({
						fontWeight: 'bold',
						fontSize: '15px',
						color: '#303030',
					})
					.add();
			}
		}
	}

	private setTheme() {
		Highcharts.theme = {
			colors: ['#7cb5ec', '#f7a35c', '#90ee7e', '#7798BF', '#aaeeee', '#ff0066',
				'#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
			chart: {
				backgroundColor: null,
				style: {
					fontFamily: 'Dosis, sans-serif',
				},
			},
			title: {
				style: {
					fontSize: '16px',
					fontWeight: 'bold',
					textTransform: 'uppercase',
				},
			},
			tooltip: {
				borderWidth: 0,
				backgroundColor: 'rgba(219,219,216,0.8)',
				shadow: false,
			},
			legend: {
				itemStyle: {
					fontSize: '14px',
					padding: '2px',
				},
			},
			xAxis: {
				gridLineWidth: 1,
				labels: {
					style: {
						fontSize: '14px',
					},
				},
			},
			yAxis: {
				labels: {
					style: {
						fontSize: '14px',
					},
				},
			},
			plotOptions: {
				candlestick: {
					lineColor: '#404048',
				},
			},
			// General
			background2: '#F0F0EA',

			lang: {
				numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
				thousandsSep: ',',
			},
		};
		Highcharts.setOptions(Highcharts.theme);
	}
}
