<div mat-dialog-title>
	<div class="title-row-container diag-row">
		<h3 class='title'>{{data.title}}</h3>
		<mat-icon (click)="close()">close</mat-icon>
	</div>
</div>
<div mat-dialog-content class="diag-row-table">
	<div *ngFor="let item of data.list">
		<div class="dialog-data-row">
			{{item}}
		</div>
		<hr>
	</div>
</div>
<div mat-dialog-actions class="dialog-button-row diag-row" align="end">
  <button class="done" mat-flat-button color="primary" (click)="close()" cdkFocusInitial>Done</button>
</div>
