import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
	withLatestFrom, mergeMap, catchError, concatMap, map, debounceTime, switchMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootState } from 'app/app.module';
import { ActivatedRoute, Params } from '@angular/router';
import { TypedAction } from '@ngrx/store/src/models';
import * as _ from 'lodash';
import { EMPTY, of, from } from 'rxjs';
import { WorkerManager, WorkerClient } from 'angular-web-worker/angular';
import {HoursHttpErrorResponse, HoursState} from './hours.types';
import { hoursFeature } from './hours.selectors';
import { HoursService } from './hours.service';
import {
	loadHours, loadHoursSuccess, loadHoursRange, filterHours, loadHoursFilters, loadHoursFiltersSuccess, loadHoursFailure,
} from './hours.actions';
import { HoursWorker } from './hours.worker';
import { filtersSelector } from '../app/app.selectors';

@Injectable()
export class HoursEffects {
	private client: WorkerClient<HoursWorker>;

	@Effect() LoadHours = this.actions$.pipe(
		ofType(loadHours, loadHoursRange),
		debounceTime(1000),
		concatMap((action) => of(action).pipe(
			withLatestFrom(this.route.queryParams, this.store.select(hoursFeature), this.store.select(filtersSelector)),
		)),
		mergeMap(([action, params, state, filterOptions]: [TypedAction<string>, Params, HoursState, any]) => {
				return this.hoursService.getReportDetailsData({
					offset: _.get(state, 'offset'),
					limit: _.get(state, 'limit'),
					...params,
				}).pipe(
					switchMap((hours) => {
						const nState = {
							...state,
							hoursData: state.offset > 0 ? [...state.hoursData, ...hours.sds] : hours.sds,
							totalNumberOfDrivers: hours.totalNumberOfDrivers,
							offset: hours.totalNumberOfDrivers > state.offset && hours.totalNumberOfDrivers > state.limit ? state.offset + state.limit : 0,
						};
						this.initWebWorker();
						return from(this.callPopulateModel(nState, params, filterOptions)).pipe(mergeMap(
							(data) => {
								this.client.destroy();
								const success = loadHoursSuccess(data);
								return nState.offset >= hours.totalNumberOfDrivers || nState.limit >= hours.totalNumberOfDrivers ? [success] : [success, loadHours()];
							},
						));
					}),
					catchError(err => {
							const errorMessage = {message: err.error.message, name: err.name, statusText: err.statusText};
							return [loadHoursFailure({error : errorMessage})];
						}
					));
			},
		));

	@Effect() filterHours = this.actions$.pipe(
		ofType(filterHours),
		concatMap((action) => of(action).pipe(
			withLatestFrom(this.route.queryParams, this.store.select(hoursFeature)),
		)),
		mergeMap(([action, params, state]: [TypedAction<string>, Params, HoursState]) => {
			this.initWebWorker();
			return from(this.callPopulateModel(state, params, [])).pipe(
				map((data) => {
					this.client.destroy();
					return loadHoursSuccess(data);
				}),
			);
		}),
	);

	@Effect() buildHoursFilters = this.actions$.pipe(
		ofType(loadHoursFilters),
		concatMap((action) => of(action).pipe(
			withLatestFrom(this.route.queryParams, this.store.select(filtersSelector)),
		)),
		mergeMap(([action, params, filters]: [TypedAction<string>, Params, any]) => {
			this.initWebWorker();
			return from(this.callPopulateFilters(filters, params, [])).pipe(
				map((data) => {
					this.client.destroy();
					return loadHoursFiltersSuccess({ filters: data });
				}),
			);
		}),
	);

	initWebWorker() {
		if (this.workerManager.isBrowserCompatible) {
			this.client = this.workerManager.createClient(HoursWorker);
		} else {
			// if code won't block UI else implement other fallback behaviour
			this.client = this.workerManager.createClient(HoursWorker, true);
		}
	}

	async callPopulateModel(dat, params, filters) {
		let outcome;
		try {
			await this.client.connect();
			outcome = await this.client.call((w) => w.populateModel(dat, params));
		} catch (exception) {
			console.error(exception);
			return null;
		}
		return outcome;
	}

	async callPopulateFilters(dat, params, filters) {
		let outcome;
		try {
			await this.client.connect();
			outcome = await this.client.call((w) => w.populateFilters(dat, params));
		} catch (exception) {
			console.error(exception);
			return null;
		}
		return outcome;
	}

	constructor(
		private route: ActivatedRoute,
		private hoursService: HoursService,
		private store: Store<RootState>,
		private actions$: Actions,
		private workerManager: WorkerManager,
	) { }
}
