import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdNavbarComponent } from './cd-navbar.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
	],
	exports: [
		CdNavbarComponent,
	],
	declarations: [
		CdNavbarComponent,
	],
})
export class CdNavbarModule {
}
