import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MalfunctionsComponent } from './malfunctions.component';

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'details',
		pathMatch: 'full',
	},
	{
		path: 'details',
		component: MalfunctionsComponent,
	},
];

@NgModule({ imports: [RouterModule.forChild(routes)], exports: [RouterModule] })
export class MalfunctionsRoutingModule { }
