import { createAction, props } from '@ngrx/store';
import { SdsState } from './sds.types';

export const loadSds = createAction(
	'[Sds] Load Sds',
);

export const loadSdsRange = createAction(
	'[Sds] Load Sds Range',
);

export const filterSds = createAction(
	'[Sds] Filter Sds',
);

export const loadSdsSuccess = createAction(
	'[Sds] Load Sds Success',
	props<SdsState>(),
);

export const loadSdsFailure = createAction(
	'[Sds] Load Sds Failure',
	props<{ error: any }>(),
);
