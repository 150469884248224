import {Component, Input, OnInit} from '@angular/core';
import {MalfunctionsService} from '../../malfunctions/malfunctions.service';
import {ActivatedRoute} from '@angular/router';
import {catchError, delay, finalize, map, skipWhile} from 'rxjs/operators';
import {BehaviorSubject, from, Observable, of, Subject} from 'rxjs';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';

@Component({
	selector: 'csv-export',
	templateUrl: './csv-export.component.html',
	styleUrls: ['./csv-export.component.scss'],
})

export class CsvExportComponent {

	showSpinner = new BehaviorSubject<boolean>(false);

	errMessage$ = new BehaviorSubject<string>('');

	private _queryParams = {}

	@Input() dataService: {
		getCSV(queryParams): Observable<any>;
	};

	@Input() dataType;

	@Input()
	set queryParams(params: any) {
		this._queryParams = params;
	}

	get queryParams(): any {
		return this._queryParams;
	}

	constructor(
		private mfService: MalfunctionsService,
		private notification: ZonarUiNotificationsService,
	) {
	}

	exportCSV(){
		const fileName = `${this.dataType}_${new Date().toISOString()}`;
		this.showSpinner.next(true);

		this.queryParams = {
			...this.queryParams,
			csv_filename : fileName
		};

		const notification$ = from(this.errMessage$).pipe(delay(1000), map((msg)=>{
			if (msg === 'No error') {
				this.createNotification();
			}
		})).subscribe();

		this.dataService.getCSV(this.queryParams).pipe(map((data)=>{
			if (data.name === 'HttpErrorResponse') {
				const blob = new Blob([data.error]).text();
				blob.then((str) => {
					let newErrorData = JSON.parse(str);
					this.errMessage$.next(newErrorData.message);
					this.createNotificationError(newErrorData);
				});
			} else {
				this.errMessage$.next('No error');
				this.writeDocument(data,fileName)
			}
		}), finalize(() => {
			if (this.errMessage$.value === 'No error'){
				this.showSpinner.next(false);
				this.notification.dismiss();
			}
			notification$.unsubscribe();
		}), catchError(err => {
			return err;
		})).subscribe();
	}

	public writeDocument(data, name) {
		const downloadURL = window.URL.createObjectURL(data);
		const link = document.createElement('a');
		link.href = downloadURL;
		link.download = `${name}.csv`;
		link.click();
	}

	createNotification(){
		return this.notification.openSuccess('Generating CSV export.', 'This is a large file and may take some time.', 35 );
	}

	createNotificationError(errObj: {details: string, message: string}) {
		of(1).pipe(delay(3000),map(()=>	this.showSpinner.next(false))).subscribe();
		return this.notification.openError(errObj.details, errObj.message, 3);
	}

}
