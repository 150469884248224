<div mat-dialog-title>
	<div class="title-row-container diag-row">
		<span class='title'>Impacted Drivers</span>
		<a (click)="close()" class="close-button">
			<img src="/assets/images/ic-clear.svg" class="close-icon">
		</a>
	</div>
	<div class="diag-row">
		There have been {{ data.driverCount }} Impacted Drivers across {{ data.eventsCount }} events: <br/>
		{{ data.driverNames }}
	</div>
</div>
<div mat-dialog-content class="diag-row-table">
	<div *ngFor="let item of data.malfunctionDriverEvents">
		<div class="dialog-data-row">
			<p>{{item.time | dateTimeTimezone }}</p>
			<p>{{item.driverFirstName}} {{item.driverLastName}}</p>
		</div>
		<hr>
	</div>
</div>
<div mat-dialog-actions class="dialog-button-row diag-row">
  <button class="done" mat-flat-button color="primary" (click)="close()" cdkFocusInitial>Done</button>
</div>
