export const SDSTypes = [
    {title: 'Yard Move', value: 'YM'},
    {title: 'High Rail', value: 'HR'},
    {title: 'Personal Conveyance', value: 'PC'},
    {title: 'Well Wait Time', value: 'WT'}
];

export const MetersToMilesConstant = 1609.344;

export const initialPageSize = 20;

export const initialPageIndex = 1;
