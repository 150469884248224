import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/material/material.module';
import {ZonarUiHosNavigationModule, HOS_APP_URL, HOS_ENV, HOS_APP_APPLICATION_IDS, HOS_APP_NAME} from '@zonar-ui/hos-navigation';
import { HeaderComponent } from './header.component';
import { DispatcherListModule } from '../dispatcher-list/dispatcher-list.module';
import { CdNavbarModule } from '../cd-navbar/cd-navbar.module';
import { environment } from 'environments/environment';
import { appNameForPerm } from 'environments/shared';
import {LocationListModule} from "../location-list/location-list.module";

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MaterialModule,
		DispatcherListModule,
		LocationListModule,
		CdNavbarModule,
		ZonarUiHosNavigationModule,
	],
	exports: [
		HeaderComponent,
	],
	declarations: [
		HeaderComponent,
	],
	providers: [
		{provide: HOS_APP_NAME, useValue: appNameForPerm},
		{provide: HOS_ENV, useValue: environment.name},
        {
            provide: HOS_APP_URL, useValue: {
                eldx: environment.urls.eldx,
                compliance: environment.appUrl
            }
        },
		{
			provide: HOS_APP_APPLICATION_IDS, useValue: {
				eldx: environment.eldxApplicationId,
				compliance: environment.auth.applicationId,
			}
		}
	]
})
export class HeaderModule {
}
