import { OptionList } from 'app/utils/options';
import * as _ from 'lodash';
import { OdometerRow, OdometerState } from './odometer.types';

export const initialOdometerState: OdometerState = {
	isLoading: false,
	isLoadSuccess: false,
	jumpEvents: [],
	tableData: {
		rows: [],
		displayedColumns: [
			'asset_name',
			'asset_location',
			'start_odo_date',
			'start_odo',
			'end_odo_date',
			'end_odo',
			'odo_distance',
		],
		headers: ['Asset', 'location', 'Start Date', 'Start Odometer', 'End Date', 'End Odometer', 'Distance (mi)'],
	},
	tableDataByAsset: {
		rows: [],
		displayedColumns: [
			'asset_name',
			'asset_location',
			'start_odo_date',
			'start_odo',
			'end_odo_date',
			'end_odo',
			'odo_distance',
			'arrow',
			'jumpEvents',
		],
		headers: ['Asset', 'Location', 'Start Date', 'Start Odometer', 'End Date', 'End Odometer', 'Distance (mi)'],
	},
	chartData: {},
	filterOptions: {
		assetLocationOptions: { options: [], selectedOption: {} },
		minOdoOptions: { options: [], selectedOption: {} },
		maxOdoOptions: { options: [], selectedOption: {} },
		assetOptions: { options: [], selectedOption: {} },
		odoTypeOptions: { options: [], selectedOption: {} },
	},
};

export class OdometerUtils {
	populateDataModel(odoState: OdometerState, params) {
		const minOdoOptions = this.makeEmptyOptionsList('All Odos', 'minOdo');
		const maxOdoOptions = this.makeEmptyOptionsList('All Odos', 'maxOdo');
		let assetLocationOptions = this.makeEmptyOptionsList('All Asset Locations', 'asset_location');
		let assetOptions = this.makeEmptyOptionsList('All Assets', 'asset_id');
		let odoTypeOptions = this.makeEmptyOptionsList('All Types', 'is_manual');

		odoTypeOptions = this.addOption('Manual', params.is_manual_odo, odoTypeOptions, 'is_manual_odo');
		odoTypeOptions = this.addOption('Automatic', params.is_manual_odo, odoTypeOptions, 'is_manual_odo');

		const byAssetLookup = {};

		const model: OdometerState = {
			...odoState,
			tableData: {
				...initialOdometerState.tableData,
				rows: [],
			},
			tableDataByAsset: {
				...initialOdometerState.tableDataByAsset,
				rows: [],
			},
			filterOptions: {
				assetLocationOptions,
				odoTypeOptions,
				minOdoOptions,
				maxOdoOptions,
				assetOptions,
			},
		};

		_.forEach(odoState.jumpEvents, (event: OdometerRow) => {
			if (this.isMinimumDifference(event.odo_distance)) {
				const tableRow = {
					...event,
					start_odo: Math.round((Number(event.start_odo) / 1609.344) * 1e0) / 1e0,
					end_odo: Math.round((Number(event.end_odo) / 1609.344) * 1e0) / 1e0,
					odo_distance: Math.round((Number(event.odo_distance) / 1609.344) * 1e0) / 1e0,
				};
				assetOptions = this.addOption(event.asset_id, params.asset_id, assetOptions, 'asset_id', event.asset_name);
				assetLocationOptions = this.addOption(event.asset_location, params.asset_location, assetLocationOptions, 'asset_location');
				if (this.filterData(tableRow, params)) {
					if (!_.get(byAssetLookup, event.asset_id)) {
						_.assign(byAssetLookup, {
							[event.asset_id]: {
								...tableRow,
								jumpEvents: [tableRow],
							},
						});
					} else {
						_.get(byAssetLookup, event.asset_id).jumpEvents.push(tableRow);
					}
					model.tableData.rows.push(tableRow);
				}
			}
		});
		model.tableDataByAsset.rows = Object.values(byAssetLookup);
		return model;
	}

	// limiting sensitivity to 1 mile and throwing out events greater than 5000 miles
	isMinimumDifference(odo_distance) {
		return ((odo_distance > 1609.34 || odo_distance < -1609.34)
		&& odo_distance < 8046720 && odo_distance > -8046720);
	}

	makeEmptyOptionsList(label: string, name: string, includeInOptions: boolean = false): OptionList {
		const selectedOption = { label, value: { name, code: '' } };
		return { options: includeInOptions ? [selectedOption] : [], selectedOption };
	}

	filterData(row, params) {
		let isMatch = true;
		_.map(params, (value: string | Array<string>, key: string) => {
			isMatch = (
				isMatch
				&& (
					row[key]
					&& value.includes(row[key].toString())
					|| !value
					|| value.length === 0
					|| value.includes('all')
					|| !(key in row)
				)
			);

			if (key === 'is_manual_odo' && isMatch && value[0] && value.includes('Manual')) {
				if (!value.includes('Automatic')) {
					isMatch = row.is_manual;
				}
			} else if (key === 'is_manual_odo' && isMatch && value[0] && value.includes('Automatic')) {
				isMatch = !row.is_manual;
			}

			if (key === 'minOdo' && isMatch && value[0]) {
				isMatch = row.odo_distance >= Number(value[0]);
			}
			if (key === 'maxOdo' && isMatch && value[0]) {
				isMatch = row.odo_distance <= Number(value[0]);
			}
		});
		return !!isMatch;
	}

	/** Add an option if missing
	@param code value of the field from a record
	@param selectedCode value of the field from the params
	@param dropDown the list of options, to be populated
	@param name the option name
	@param label the label for value from the record if different from the value itself
	*/
	addOption(code: string, selectedCode: string, dropDown: OptionList, name: string, label?: string): OptionList {
		if (code && !_.some(dropDown.options, (option) => (option.value.code === code))) {
			const newOption = {
				label: label || code,
				value: { name, code },
			};

			dropDown.options.push(newOption);
			if (_.includes(selectedCode, code)) {
				dropDown.selectedOption = newOption;
			}
		}
		return dropDown;
	}
}
