import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MaterialModule} from "../../material/material.module";
import {ZonarUiSearchableDropdownModule} from "@zonar-ui/searchable-dropdown";
import {DriverListService} from "../dispatcher-list/driver-list.service";
import {LocationListComponent} from "./location-list.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        ZonarUiSearchableDropdownModule,
        FormsModule,
    ],
    exports: [
       LocationListComponent
    ],
    providers: [
        DriverListService,
    ],
    declarations: [
        LocationListComponent,
    ],
})
export class LocationListModule {
}
