import { OptionEntry } from '../../utils/options';

export const DAY_RANGES: Array<OptionEntry> = [
	{ label: 'Last 3 Days', value: { name: 'days', code: '3' } },
	{ label: 'Last 5 Days', value: { name: 'days', code: '5' } },
	{ label: 'Last 7 Days', value: { name: 'days', code: '7' } },
	{ label: 'Last 30 Days', value: { name: 'days', code: '30' } },
	{ label: 'Last 90 Days', value: { name: 'days', code: '90' } },
	{ label: 'Last 180 Days', value: { name: 'days', code: '180' } },
];
