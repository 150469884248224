import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ZuiDatePickerModule } from '@zonar-ui/date-picker';
import { ZonarUiHosComponentsModule } from '@zonar-ui/hos-components';
import { SharedReportsComponentsModule } from '../shared-report-components/shared-report-components.module';

import { MaterialModule } from '../../material/material.module';
import { DataTableModule } from '../../components/data-table/data-table.module';
import { MalfunctionsComponent } from './malfunctions.component';
import { MalfunctionsRoutingModule } from './malfunctions-routing.module';
import { ZonarUiHosFiltersContainerModule } from '@zonar-ui/hos-filters-container';
import {HighchartModule} from '../../components/highchart/highchart.module';
import {MalfunctionsKpiComponent} from './malfunctions-kpi/malfunctions-kpi.component';

@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
		SharedReportsComponentsModule,
		DataTableModule,
		ZuiDatePickerModule,
		ZonarUiHosComponentsModule,
		MalfunctionsRoutingModule,
		ZonarUiHosFiltersContainerModule,
		HighchartModule,
	],
	declarations: [
		MalfunctionsComponent,
		MalfunctionsKpiComponent
	],
	exports: [
		MalfunctionsKpiComponent
	],
})
export class MalfunctionsModule { }
