import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface MultipleListDialogData {
	title: string;
	list: any[];
}

@Component({
	selector: 'show-multiple-list-dialog',
	templateUrl: './show-multiple-list-dialog.html',
	styleUrls: ['./show-multiple-list-dialog.scss'],
})
export class ShowMultipleListDialogComponent {

	constructor(public dialogRef: MatDialogRef<ShowMultipleListDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: MultipleListDialogData) { }

	close(): void {
		this.dialogRef.close();
	}
}
