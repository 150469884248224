import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { OptionList } from 'app/utils/options';
import {SdsState, SpecialDutyStatusesEntity, Sds, sdsChartData, SDSEvent} from './sds.types';
import {HumanizeDurationPipe} from "../../pipes/humanize-duration.pipe";
import {MetersToMilesConstant} from "../../reports/sds/sds.constants";


export const sdsFeatureKey = 'sds';

const MAX_LIKELY_SDS_DISTANCE = 1000 * MetersToMilesConstant;

export const emptySDSchartData = {
		YM: 0,
		PC: 0,
		WT: 0,
		HR: 0
	};

const ALL_HOME_LOCATIONS = { label: 'Home', value: { name: 'Home', code: '' } };

export const initialSdsState: SdsState = {
	isLoading: false,
	isLoadSuccess: false,
	totalNumberOfDrivers: 0,
	offset: 0,
	limit: 100,
	tableData: {
		rows: [],
		displayedColumns: [
			'driverName',
			'fleet',
			'driverHomeLocation',
			'start_epoch',
			'end_epoch',
			'total_time',
			'start_location',
			'clear_location',
			'distance',
			'sdsType',
			'exsid',
		],
		headers: ['DRIVER', 'ASSET', 'LOCATION', 'START', 'END', 'TOTAL TIME', 'START LOCATION', 'END LOCATION', 'MILES', 'TYPE', 'EXSID'],
	},
	tableDataByDriver: {
		rows: [],
		displayedColumns: [
			'driverName',
			'driverHomeLocation',
			'exsid',
			'totalEvents',
			'arrow',
		],
		headers: ['DRIVER', 'LOCATION', 'EXSID', 'Total Events'],
	},
	chartData: {
		YM: 0,
		PC: 0,
		WT: 0,
		HR: 0,
	},
	sdsData: [],
	filterOptions: {
		driverOptions: { options: [], selectedOption: {} },
		exsidOptions: { options: [], selectedOption: {} },
		startLocationOptions: { options: [], selectedOption: {} },
		endLocationOptions: { options: [], selectedOption: {} },
		fleetOptions: { options: [], selectedOption: {} },
		locationOptions: { options: [ALL_HOME_LOCATIONS], selectedOption: ALL_HOME_LOCATIONS },
		sdsOptions: {
			options: [
				{ label: 'Yard Move', value: { name: 'sdsType', code: 'YM' } },
				{ label: 'High Rail', value: { name: 'sdsType', code: 'HR' } },
				{ label: 'Personal Conveyance', value: { name: 'sdsType', code: 'PC' } },
				{ label: 'Well Wait Time', value: { name: 'sdsType', code: 'WT' } },
			],
			selectedOption: { label: 'All SDS Types', value: { name: 'sdsType', code: '' } },
		},
	},
};

const allSDSTypes = ['YM', 'PC', 'WT', 'HR'];

export class SdsUtils {

	populateDataModel(sdsState: SdsState, params): SdsState {
		let locationOptions = this.makeEmptyOptionsList('All Home Locations', 'driverHomeLocation');
		let driverOptions = this.makeEmptyOptionsList('All Drivers', 'driverId');
		let exsidOptions = this.makeEmptyOptionsList('All Exsids', 'exsid');
		let startLocationOptions = this.makeEmptyOptionsList('All Start Locations', 'start_location');
		let endLocationOptions = this.makeEmptyOptionsList('All End Locations', 'clear_location');
		const sdsRowsByDriver = {};
		let fleetOptions = this.makeEmptyOptionsList('Asset', 'fleet');

		const sdsOptions = initialSdsState.filterOptions.sdsOptions;
		const sdsByDriver = sdsState.sdsData;

		const model: SdsState = {
			...sdsState,
			tableData: {
				...initialSdsState.tableData,
				rows: [],
			},
			tableDataByDriver: {
				...initialSdsState.tableDataByDriver,
				rows: [],
			},
			chartData: {
				YM: 0,
				PC: 0,
				WT: 0,
				HR: 0,
			},
			filterOptions: {
				locationOptions,
				sdsOptions,
				driverOptions,
				exsidOptions,
				startLocationOptions,
				endLocationOptions,
				fleetOptions,
			},
		};

		_.forEach(sdsByDriver, ({
			timezone, name, driver_location, driver, summary, exsid,
		}: Sds) => {
			let isMatch = false;

			_.forEach(_.get(summary, 'duty_statuses'), (dutyStatusEvents) => {
				_.forEach(dutyStatusEvents.special_duty_statuses, ({
					start_epoch,
					end_epoch,
					distance,
					event_code_name,
					start_location,
					clear_location,
					start_asset_id,
					fleet,
				}: SpecialDutyStatusesEntity) => {
					isMatch = true;
					const row = {
						driverName: name,
						driverId: driver,
						driverHomeLocation: driver_location,
						fleet,
						start_epoch: moment(start_epoch * 1000).tz(timezone).format(),
						end_epoch: moment(end_epoch * 1000).tz(timezone).format(),
						total_time: end_epoch - start_epoch,
						distance,
						sdsType: event_code_name,
						exsid,
						start_location,
						clear_location,
						asset: start_asset_id,
						timezone,
					};
					const driverSdsRow = {
						start_epoch: row.start_epoch,
						end_epoch: row.end_epoch,
						sdsType: row.sdsType,
						total_time: row.total_time,
						distance: row.distance,
						start_location: row.start_location,
						clear_location: row.clear_location,
						asset: row.asset,
					};

					if (this.filterData(row, params)) {
						model.tableData.rows.push(row);
						if (!_.get(sdsRowsByDriver, row.driverId)) {
							sdsRowsByDriver[row.driverId] = {
								driverHomeLocation: row.driverHomeLocation,
								driverId: row.driverId.toString(),
								driverName: row.driverName,
								exsid: row.exsid,
								totalEvents: 1,
								timezone: row.timezone,
								sdsEvents: [driverSdsRow],
							};
						} else {
							sdsRowsByDriver[row.driverId].totalEvents++;
							sdsRowsByDriver[row.driverId].sdsEvents.push(driverSdsRow);
						}
						_.update(model.chartData, event_code_name, (val) => (val ? val + 1 : 1));
					}

					if (isMatch) {
						driverOptions = this.addOption(driver.toString(), params.driverId, driverOptions, 'driverId', name);
						locationOptions = this.addOption(driver_location, params.driverHomeLocation, locationOptions, 'driverHomeLocation', driver_location);
						exsidOptions = this.addOption(exsid, params.exsid, exsidOptions, 'exsid', exsid);
						startLocationOptions = this.addOption(start_location, params.start_location, startLocationOptions, 'start_location', start_location);
						endLocationOptions = this.addOption(clear_location, params.clear_location, endLocationOptions, 'clear_location', clear_location);
						fleetOptions = this.addOption(fleet, params.fleet, fleetOptions, 'fleet', fleet);
					}
				});
			});
		});

		if (params.sdsType) {
			sdsOptions.selectedOption = _.filter(sdsOptions.options, (option) => option.value.code === params.sdsType);
		}

		locationOptions.options = _.sortBy(locationOptions.options, 'label');
		driverOptions.options = _.sortBy(driverOptions.options, 'label');
		sdsOptions.options = _.sortBy(sdsOptions.options, 'label');
		exsidOptions.options = _.sortBy(exsidOptions.options, 'label');
		startLocationOptions.options = _.sortBy(startLocationOptions.options, 'label');
		endLocationOptions.options = _.sortBy(endLocationOptions.options, 'label');
		model.tableDataByDriver.rows = Object.values(sdsRowsByDriver);

		return model;
	}

	makeEmptyOptionsList(label: string, name: string, includeInOptions: boolean = false): OptionList {
		const selectedOption = { label, value: { name, code: '' } };
		return { options: includeInOptions ? [selectedOption] : [], selectedOption };
	}

	filterData(row, params) {
		let isMatch = true;

		_.map(params, (value: string | Array<string>, key: string) => {
			isMatch = (
				isMatch
				&& (
					row[key]
					&& value.includes(row[key].toString())
					|| !value
					|| value.length === 0
					|| value.includes('all')
					|| !(key in row)
				)
			);
		});

		return !!isMatch;
	}

	/** Add an option if missing
	@param code value of the field from a record
	@param selectedCode value of the field from the params
	@param dropDown the list of options, to be populated
	@param name the option name
	@param label the label for value from the record if different from the value itself
	*/
	addOption(code: string, selectedCode: string, dropDown: OptionList, name: string, label?: string): OptionList {
		if (code && !_.some(dropDown.options, (option) => (option.value.code === code))) {
			const newOption = {
				label: label || code,
				value: { name, code },
			};

			dropDown.options.push(newOption);
			if (_.includes(selectedCode, code)) {
				dropDown.selectedOption = newOption;
			}
		}
		return dropDown;
	}


	static reshapeForDataTableAndChartView(sdsDrivers: any, sds_types = []) {
		let dataTable = [];
		// this is the final chart Data that is a cumulative of the daily chart data for every driver
		let accChartData: sdsChartData = {
			HR: 0,
			PC: 0,
			YM: 0,
			WT: 0,
		};
		sdsDrivers.forEach((driver) => {
			if (!!driver?.sds_events) {
				let chartData = {};
				let driver_name = `${driver?.driver_info?.first_name} ${driver?.driver_info?.last_name}` || 'Unknown';
				let exsid = driver?.driver_info?.exsid ? `${driver?.driver_info?.exsid}` : 'Unknown';
				let driver_home_location = driver?.driver_info?.home_location.name || 'Unknown';
				const timezone = driver?.driver_info?.timezone;
				for (const sdsEventObj of driver?.sds_events) {
		 			//close the empty row, populate values based on key map, and accumulative chart data
					let row;
					// filters sds_types
					[row, chartData] = this.configTableRowAndChartData(sdsEventObj, timezone, sds_types);
					if (!!row) {
						accChartData = this.updateAccChartData(accChartData, chartData);
						row = {...row, driver_name, exsid, driver_home_location};
						dataTable.push(row);
					}
				}
			}
		});
		return [dataTable, accChartData];
	}

	static configTableRowAndChartData(sdsEventObj: SDSEvent, timezone, sds_types = []) {
		let sdsChartData;
		let data;
		let total_time;

		const emptySDSRow = {
			driver_name: 'Test-name',
			driver_home_location: 'Test-home',
			exsid: 'Test-exsid',
			fleet: '',
			start_epoch: '',
			end_epoch: '',
			total_time: null,
			start_location: '',
			clear_location: '',
			distance: null,
			sdsType: '',
			asset: '',
			sds_type:''
		};

		const convertToMiles = (distInMeters) => {
			return Math.round((distInMeters*10/MetersToMilesConstant)/10);
		};

		const filterPipe = new HumanizeDurationPipe;

		const includeEventObj = ((sds_types.length === 0) || (sds_types.length > 0 && sds_types.includes(sdsEventObj.event_code_name)));
		if (includeEventObj) {
			sdsChartData = _.clone(emptySDSchartData);
			data = _.clone(emptySDSRow);
			let fleetName = sdsEventObj.start_fleet ? sdsEventObj?.start_fleet.toString() :
				(sdsEventObj?.start_asset_id ? sdsEventObj?.start_asset_id.toString() : 'Unknown');
			data.fleet = fleetName;
			data.asset = fleetName;
			data.start_epoch = timezone ? moment(Number(sdsEventObj.start_epoch) * 1000).tz(timezone).format() :
				moment(Number(sdsEventObj.start_epoch) * 1000).format();
			data.end_epoch = timezone ? moment(Number(sdsEventObj.end_epoch) * 1000).tz(timezone).format():
				moment(Number(sdsEventObj.end_epoch) * 1000).format();
			total_time = Number(sdsEventObj.end_epoch) - Number(sdsEventObj.start_epoch);
			data.totalTime = total_time;
			data.total_time = filterPipe.transform(total_time, 's');
			data.sds_type = sdsEventObj.event_code_name;
			data.start_location = sdsEventObj.start_location || 'Unknown';
			data.clear_location = sdsEventObj.clear_location || 'Unknown';
			data.distance = (sdsEventObj.distance && sdsEventObj?.distance >0 && sdsEventObj.distance < MAX_LIKELY_SDS_DISTANCE)? convertToMiles(sdsEventObj.distance) : 'Unknown';
			sdsChartData[data.sds_type]++;
		}
		return [data, sdsChartData];
	}

	static forGroupByView(response: any, sds_types = []) {
		let dataTable = [];
		const emptyGroupBySDSRow = {
			driver_name: 'Test-name',
			driver_home_location: 'Test-home',
			exsid: 'Test-exsid',
			total_events: 0,
			driverId: '',
			sds_events: []
		};
		response.forEach((driver) => {
			if (driver?.sds_events && driver['sds_events'] !== []) {
				let chartData = {};
				let data = _.clone(emptyGroupBySDSRow);
				let driver_name = `${driver?.driver_info?.first_name} ${driver?.driver_info?.last_name}`;
				let exsid = driver?.driver_info?.exsid ? `${driver?.driver_info?.exsid}` : 'Unknown';
				let driver_home_location = driver?.driver_info?.home_location.name || 'Unknown';
				let driverId = driver?.driver_info?.driver_id.toString() || 'Unknown';
				const timezone = driver?.driver_info?.timezone;
				let sds_events = [];
				for (const sdsEventObj of driver?.sds_events) {
					let row;
					// filters sds_types
					[row, chartData] = this.configTableRowAndChartData(sdsEventObj, timezone, sds_types);
					if(!!row) {
						sds_events.push(row);
					}
				}
				data = {...data, driver_name, driver_home_location, exsid, sds_events, total_events: sds_events?.length || 0, driverId};
				if (data.total_events > 0) {
					dataTable.push(data);
				}
			}
		});
		return dataTable;
	}

	static updateAccChartData(aCD: sdsChartData, chartData: any) {
		aCD.HR += chartData.HR;
		aCD.PC += chartData.PC;
		aCD.WT += chartData.WT;
		aCD.YM += chartData.YM;
		return aCD;
	}

}
