import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'error-component',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
	constructor() { }

	ngOnInit() {
	}
}
