import { NgModule } from '@angular/core';
import { ApiConfiguration } from './api-configuration';

/**
 * Module that provides instances for all API services
 */
@NgModule({
	imports: [],
	exports: [],
	declarations: [],
	providers: [
		ApiConfiguration,
	],
})
export class ApiModule { }
