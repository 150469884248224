import { TemplatePortal } from '@angular/cdk/portal';
import {
	AfterViewInit,
	Component, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { ReportHeader } from '../../reports/report-header.model';
import { MalfunctionsService } from './malfunctions.service';
import { Store } from '@ngrx/store';
import { getContextualHelp } from 'app/state/app/app.actions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { RootState } from '../../app.module';
import { AssetMalfunctions, Malfunction, MalfunctionDriver } from './malfunction-types';
import { malfunctionsColumns, MalfunctionsDataSource } from './malfunctions.datasource';
import {BehaviorSubject, of} from 'rxjs';
import { FilterService } from '../../state/filter.service';
import { MalfunctionDurations, malfunctionStatus, MalfunctionTypeDropdowns, selectedFilter } from './malfunctions.constants';
import { FilterChange } from '@zonar-ui/hos-filters-container';
import { HosTableCellType, HosTableColumn, HosTableComponent } from '@zonar-ui/hos-components';
import { FormGroup } from '@angular/forms';
import { ShowMultipleListDialogComponent } from '../shared-report-components/show-multiple-list-dialog/show-multiple-list-dialog';
import {environment} from '../../../environments/environment';
import {Title} from "@angular/platform-browser";
import {DriverSearchTabs, DateRangeWithToday} from '../reports.constants';
import {DispatcherLocationToggleService} from "../../dispatcherLocationToggle.service";
import {DropdownOption} from "@zonar-ui/searchable-dropdown";


const headers = ['ASSET #', 'TYPE', 'START DATE/TIME', 'CLEAR DATE/TIME', 'DURATION IN HOURS', 'STATUS', 'DRIVER HOME LOCATION', 'DRIVER NAME'];


@Component({
	selector: 'app-malfunctions',
	templateUrl: './malfunctions.component.html',
	styleUrls: ['./malfunctions.component.scss'],
})
export class MalfunctionsComponent implements OnInit, OnDestroy, OnChanges {

	@ViewChild('currentDriver', { static: true }) currentDriverCellTemplate: TemplateRef<any>;

	@ViewChild('displayMalfunctionType', { static: true }) malfunctionTypeCellTemplate: TemplateRef<any>;

	@ViewChild('malfunctionsTable') zonarTable: HosTableComponent;

	@ViewChild('multipleTextTemplate', { static: true }) multipleTextTemplate: TemplateRef<any>;

	params = {};

	isLoading = true;

	timeOptions: { options: Array<any>, selectedOption: {} };

	malfunctionsTableDialogData = null;

	dialogIsLoading = false;

	tableColumns;

	tableDataSource: MalfunctionsDataSource;

	allLocations = new BehaviorSubject<DropdownOption[]>([]);

	public reportHeader: ReportHeader = { value: 'Compliance Dashboard Main', routerLink: ['/'] };

	service = this.filterService;
	searchDropdownTabs = DriverSearchTabs.concat({
		tableName: 'Assets',
		displayTableName: 'Assets',
		queryParamName: 'asset_id'
	});

	dataForFilters = [{
		type: 'search',
		options: {
			tables: this.searchDropdownTabs,
			service: this.service
		}
	}, {
		type: 'date',
		options: {
			datePreset: DateRangeWithToday,
			months: 6,
			multiSelect: true,
			defaultVal: selectedFilter,
			queryParamName: ['period_start', 'period_end']
		}
	}, {
		type: 'custom-dropdown',
		options: {
			label: 'Location',
			data: this.allLocations,
			isMultiple: true,
			defaultVal: null,
			fgControlName: 'location',
			queryParamName: 'location_id',
			valueType: 'number',
		}
	}, {
		type: 'dropdown',
		options: {
			label: 'Status',
			data: of(malfunctionStatus.options),
			defaultVal: 'true',
			isMultiple: false,
			class: 'default-width-no-checkboxes',
			fgControlName: 'status',
			queryParamName: 'status' // TODO: actual query param is active, but it's a conflict with the active account param.
		}
	}, {
		type: 'dropdown',
		options: {
			label: 'Duration',
			data: of(MalfunctionDurations.options),
			isMultiple: false,
			fgControlName: 'duration',
			class: 'default-width-no-checkboxes',
			queryParamName: 'min_duration',
			valueType: 'number',
		}
	}, {
		type: 'dropdown',
		options: {
			label: 'Malfunction Type',
			data: of(MalfunctionTypeDropdowns.options),
			isMultiple: true,
			fgControlName: 'malfunctionTypes',
			queryParamName: 'malfunction_type'
		}
	}, {
		type: 'input',
		options: {
			label: 'Min Malfunctions',
			default: null,
			isMultiple: false,
			fgControlName: 'min_malfunctions',
			queryParamName: 'min_malfunctions',
			class: 'minDist',
			valueType: 'number',
			validatorFunction: (fb: FormGroup) => {
				if (!fb || Object.keys(fb.controls).length === 0) {
					return;
				}
				fb.get('min_malfunctions').setErrors(null);
				const val = fb.get('min_malfunctions').value;
				if (val === undefined || val === '') {
					return;
				}
				// validation for minimum value
				const minNumber = Number(fb.get('min_malfunctions').value);
				if (minNumber <= 0) {
					fb.get('min_malfunctions').setErrors({
						errors: 'Value must be 1 or higher'
					});
					return;
				}
				if (minNumber % 1 !== 0) {
					fb.get('min_malfunctions').setErrors({
						errors: 'Whole numbers only'
					});
					return;
				}
				return;
			}
		}
	}];

	queryParams = ['driver_id', 'asset_id', 'period_start', 'period_end', 'location_id', 'status', 'min_duration', 'malfunction_type', 'min_malfunctions'];

	public groupByAssetFilter = false;
	public tableTitle = 'All Malfunctions';
	private malfunctionsColumnsGroupByAsset: HosTableColumn[] = [];

	constructor(
		private ngZone: NgZone,
		private router: Router,
		public route: ActivatedRoute,
		public malfunctionsService: MalfunctionsService,
		public dialog: MatDialog,
		private viewContainerRef: ViewContainerRef,
		private store: Store<RootState>,
		public filterService: FilterService,
		private reportsTitleService: Title,
		private dispatcherLocationToggleService: DispatcherLocationToggleService
	) {
		this.reportsTitleService.setTitle('Malfunctions');
		this.dispatcherLocationToggleService.showHideToggle.next(false);
		this.dispatcherLocationToggleService.showLocationFilter.next(false);
		this.filterService.getLocations().subscribe(allLoc => {
			if (allLoc) {
				this.allLocations.next(allLoc);
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
	}

	ngOnInit(): void {
		malfunctionsColumns.forEach(m => {
			if (m.columnDef === 'malfunction_driver_name') {
				m.template = this.multipleTextTemplate;
			}
			if (m.columnDef === 'currentDriver') {
				m.template = this.currentDriverCellTemplate;
			}
			if (m.columnDef === 'displayMalfunctionType') {
				m.template = this.malfunctionTypeCellTemplate;
			}
		});
		this.buildGroupByAssetColumns(malfunctionsColumns);
		this.tableColumns = malfunctionsColumns.slice();
	}

	buildGroupByAssetColumns(malfunctionColumns) {
		const columnsToDisplayOnGroupByAsset = malfunctionColumns.filter(c => c.columnDef !== 'assetNumber')
		this.malfunctionsColumnsGroupByAsset = [{
			columnDef: 'assetNumber', header: 'ASSET #', sortable: false,
			cell: (row: AssetMalfunctions) => row.assetId || 'Unknown',
			type: HosTableCellType.Text
		},
		{
			columnDef: 'malfunctions', header: 'MALFUNCTION', sortable: false,
			cell: (row: AssetMalfunctions) => `${row.total}`,
			type: HosTableCellType.Text,
			columnStyle: { flex: '0 0 12%' }
		},
		{
			columnDef: 'displayType', header: 'TYPE', sortable: false,
			cell: (row: AssetMalfunctions) => row.displayTypes || 'Unknown',
			type: HosTableCellType.Custom,
			template: this.malfunctionTypeCellTemplate,
		},
		{
			columnDef: 'impactedDrivers', header: 'IMPACTED DRIVERS', sortable: false,
			cell: (row: AssetMalfunctions) => row.displayImpactedDrivers || 'Unknown',
			type: HosTableCellType.Custom,
			template: this.multipleTextTemplate,
		},
		{
			columnDef: 'arrow', header: '', details: (row: AssetMalfunctions) => ({
				table: {
					displayedColumns: columnsToDisplayOnGroupByAsset.map(c => c.columnDef),
					columnDefs: columnsToDisplayOnGroupByAsset,
					footerColumns: ['footerText'],
					data: row.malfunctions,
					footerText: this.createFooterText(row, this.router.url)
				}
			}), type: HosTableCellType.Details,
			columnStyle: { flex: '0 0 2%', 'padding-right': '24px' }
		}];
	}

	ngOnDestroy() {
		// Unsubscribe subscriptions on DataSource
		if (this.tableDataSource && this.tableDataSource.subs) {
			this.tableDataSource.subs.forEach((s) => {
				s.unsubscribe();
			});
		}
	}

	openModalToShowList(row: any, column: HosTableColumn) {
		if (column.columnDef === 'malfunction_driver_name') {
			this.getDriverLoginData(row);
			return;
		}
		if(column.columnDef === 'displayType' || column.columnDef === 'impactedDrivers') {
			const displayType = (column.columnDef === 'displayType');
			const list = displayType ? row.types: row.impactedDrivers;
			const title = displayType ? `Malfunction Types (${list.length})`: `Impacted Drivers (${list.length})`;
			this.dialog.open(ShowMultipleListDialogComponent, {
				width: '490px',
				data: {
					title,
					list: list.sort((a, b) => a.localeCompare(b))
				},
			})
		}
	}

	getDriverLoginData(malfunction: Partial<Malfunction>) {
		if (!this.dialogIsLoading) {
			this.dialogIsLoading = true;
			// if a malfunction has no endTime, set endTime as current date
			const endTime: string = moment(malfunction.endTime).toISOString() || moment().toISOString();
			const startTime: string = moment(malfunction.startTime).toISOString()

			this.malfunctionsService.getDriversForMalfunction(malfunction.assetId.split(":")[1], startTime, endTime).subscribe((
				malfunctionDriverEvents: Partial<MalfunctionDriver>[],
			) => {
				if (malfunction.driverFirstName || malfunction.driverLastName) {
					malfunctionDriverEvents.push({
						time: 'Logged in when malfunction started',
						driverFirstName: malfunction.driverFirstName,
						driverLastName: malfunction.driverLastName,
						driverId: +malfunction.startingDriverProfileId.split(':')[1],
					});
				}

				const malfunctionDrivers: Partial<MalfunctionDriver>[] = _.uniqBy(malfunctionDriverEvents, (e) => e.driverId);
				const driverCount = malfunctionDrivers.length;
				const driverNames = _.map(malfunctionDrivers, (d) => `${d.driverFirstName} ${d.driverLastName}`).join(', ');

				this.dialog.open(DialogComponent, {
					panelClass: 'custom-dialog-container',
					width: '490px',
					height: '600px',
					data: {
						malfunctionDriverEvents,
						driverCount,
						driverNames,
						eventsCount: malfunctionDriverEvents.length,
					},
				});

				this.dialogIsLoading = false;
			},
				(err) => {
					this.dialogIsLoading = false;
				});
		}
	}

	openContextualHelp(contextId: string) {
		this.store.dispatch(getContextualHelp({ id: contextId, helpType: 'malfunctions' }));
	}

	groupByAsset(val: boolean) {
		this.groupByAssetFilter = val;
		if (this.groupByAssetFilter) {
			this.tableColumns = this.malfunctionsColumnsGroupByAsset;
			this.tableTitle = 'Malfunctions grouped by asset';
		} else {
			this.tableColumns = malfunctionsColumns;
			this.tableTitle = 'All Malfunctions';
		}
		// reset the page and offset to zero
		this.tableDataSource.currentParams.page = 1;
		this.tableDataSource.currentParams.offset = 0;
		this.zonarTable?.resetPaging();
		this.tableDataSource.filtersChanged({ ...this.params, 'group_by_asset': val });
	}

	filtersChanged(filterChange: FilterChange) {
		if (this.zonarTable) {
			this.tableDataSource.destroy$.next(true);
			this.zonarTable.resetPaging();
		}
		let filters = filterChange.filters;
		// Mapping status filter to active query param, because active query param name is conflicting with active account.
		if ("status" in filters) {
			filters = {
				...filters,
				active: filters.status,
			}
		}
		this.params = filters;
		if (this.groupByAssetFilter) {
			this.params['group_by_asset'] = true;
		} else {
			this.params['group_by_asset'] = false;
		}
		// Initializing table data source here, if we initialize it on constructor. API call is happening twice.
		if (!this.tableDataSource) {
			this.tableDataSource = new MalfunctionsDataSource(
				this.malfunctionsService,
				this.params,
			);
		} else {
			// reset the page and offset to zero
			this.tableDataSource.currentParams.page = 1;
			this.tableDataSource.currentParams.offset = 0;
			this.tableDataSource.destroy$.next(true);
			this.tableDataSource.filtersChanged(this.params);
		}
		this.tableDataSource.loading$.subscribe((x) => {
			this.isLoading = x;
		});
	}

	public createFooterText(malfunction: AssetMalfunctions, url) {
		const valueToShowFooter = 25;
		if (malfunction.total >= valueToShowFooter) {
			let splitUrl = url.split('?');
			let base = splitUrl[0];
			let qParams = splitUrl[1] ? splitUrl[1] : undefined;
			let assetQueryParam = `asset_id_chip=${malfunction.assetId}:${malfunction.assetDBId}`;

			if (qParams) {
				// remove all asset_id_chip
				if (qParams.includes('asset_id_chip=')) {
					//if current url has multiple assets in filter
					let assets = this.params['asset_id'];
					assets.forEach((assetDBId) => {
						let regex = `[&]?asset_id_chip[=][\\s\\S\\d\\D]*[:]${assetDBId}`;
						let result = qParams.match(regex);
						if (result) {
							qParams = qParams.replace(result[0], '');
						}

					});
				}
			}

			//build html
			let html = `${environment.appUrl}${base}?${assetQueryParam}`;
			if (qParams) {
				//if qParams starts with & symbol
				if (qParams[0] == '&') {
					qParams = qParams.slice(1);	
				}
				html = `${html}&${qParams}`;
			}
			return {
				text: `See All Malfunctions (${malfunction.total})`,
				html: html,
				column: 8
			};
		} else {
			return undefined;
		}
	}

}
