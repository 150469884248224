import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../app.module';
import { AppState } from './app-types';

export const appFeature = createFeatureSelector<RootState, AppState>('app');
export const selectedEntitySelector = createSelector(appFeature, (state) => state.selectedEntity);
export const isLocationSelectedSelector = createSelector(selectedEntitySelector, (selectedEntity) => selectedEntity.entityType === 'locationId');
export const entityLabelSelector = createSelector(selectedEntitySelector, isLocationSelectedSelector,
	(selectedEntity, isLocationSelected) => ({ label: selectedEntity.label, isLocationSelected }));
export const userDataSelector = createSelector(appFeature, (state) => ({
	selectedEntity: state.selectedEntity,
	userId: state.userId,
	displayName: state.displayName,
	isAdmin: state.isAdmin,
	companyName: state.companyName,
}));
export const filtersSelector = createSelector(appFeature, (state) => state.filterOptions);
export const filtersLocationSelector = createSelector(appFeature, (state) => state.filterOptions.locations);
export const entityMenusAndUserData = createSelector(appFeature, (state) => ({
	selectedEntity: state.selectedEntity,
	userId: state.userId,
	displayName: state.displayName,
	isAdmin: state.isAdmin,
	companyName: state.companyName,
	dispatchers: state.dispatchers,
	locations: state.locations,
	isLoading: state.isLoading,
	isLoadSuccess: state.isLoadSuccess,
}));

export const locationSelector = createSelector (appFeature, (state) => state.locations);
