import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SelectedEntity} from "./state/app/app-types";


@Injectable({
    providedIn: 'root',
})
export class DispatcherLocationToggleService {
    showHideToggle = new BehaviorSubject<boolean>(true);
    showLocationFilter = new BehaviorSubject<boolean>(false);
    selectedLocationEntity$ = new BehaviorSubject<SelectedEntity>({label: '', value: '', entityType: ''});

    constructor() {}

    getToggleStatus() {
        return this.showHideToggle.asObservable();
    }

    getLocationFilterStatus() {
        return this.showLocationFilter.asObservable();
    }

    setLocationEntity(entity) {
        this.selectedLocationEntity$.next(entity);
    }

    getLocationEntity() {
        return this.selectedLocationEntity$.asObservable();
    }

}
