import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
	catchError,
	debounceTime, finalize, mergeMap, pluck, switchMap, take, withLatestFrom,
} from 'rxjs/operators';
import { Params, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from 'app/app.module';
import { TypedAction } from '@ngrx/store/src/models';
import {
	GET_DRIVER_AVAILABILITY,
	GetDriverAvailability, GetDriverAvailabilityFailed,
	GetDriverAvailabilitySuccess,
	REFRESH_DRIVER_AVAILABILITY,
} from './availability.actions';
import { AvailabilityService } from './availability.service';
import { AvailabilityAPIReturn, AvailabilityDetails } from './availability.types';
import { availabilityFeature } from './availability.selectors';
import * as _ from 'lodash';
import {throwError} from 'rxjs';


//todo: is this still being used?
@Injectable()
export class GetAvailabilityEffects {
	@Effect() setAvailability = this.actions$.pipe(ofType(GET_DRIVER_AVAILABILITY, REFRESH_DRIVER_AVAILABILITY),
		// debounceTime(100),
		withLatestFrom(this.route.queryParams),
		withLatestFrom(this.store.select(availabilityFeature)),
		mergeMap(([[action, params], state]: [[TypedAction<string>, Params], AvailabilityDetails]) => {
			return this.availabilityService.getReportDetailsData({offset: _.get(state, 'offset'), limit: _.get(state, 'limit')}).pipe(
				switchMap((availability: AvailabilityAPIReturn) => {
					const availabilityDetails = this.availabilityService.renderDataModel({
						...availability,
						availability: _.concat(state.availability, availability.body.report),
						offset: availability.body.offset,
						limit: availability.body.limit,
						total_number_of_drivers: availability.body.total_number_of_drivers
					}, params);
					if (availabilityDetails.offset < availabilityDetails.total_number_of_drivers) {
						return [
							new GetDriverAvailabilitySuccess(availabilityDetails),
							new GetDriverAvailability(),
						];
					}
					return [new GetDriverAvailabilitySuccess(availabilityDetails)];
				}),
				catchError(err => {
					return [new GetDriverAvailabilityFailed(err)];
				}),
			);
		}));

	constructor(
		private route: ActivatedRoute,
		private actions$: Actions,
		private store: Store<RootState>,
		private availabilityService: AvailabilityService,
	) { }
}
