import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { setSelectedEntity } from 'app/state/app/app.actions';
import { map } from 'rxjs/operators';
import { RootState } from '../../app.module';
import { Dispatcher, Location } from '../../state/app/app-types';
import { entityMenusAndUserData } from '../../state/app/app.selectors';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

type ToggleType = 'locationId' | 'dispatcherId';

@Component({
	selector: 'dispatcher-list',
	templateUrl: './dispatcher-list.component.html',
	styleUrls: ['./dispatcher-list.component.scss'],
})
export class DispatcherListComponent implements OnInit {

	public dispatchers: Array<Dispatcher>;

	public locations: Array<Location>;

	public filteredResults: Dispatcher[] | Location[] = [];

	public dispatcherLocationFormGroup: FormGroup = this.fb.group({});

	public selectedEntity = { label: '', value: '', entityType: '' };

	public currentToggleType: ToggleType = 'locationId';

	public toggleChecked: boolean = false;

	constructor(
		public store: Store<RootState>,
		private fb: FormBuilder,
	) {
		this.dispatcherLocationFormGroup.addControl('dispatcherLocationId', new FormControl());
		this.dispatcherLocationFormGroup.addControl('listedValue', new FormControl());
		this.store.select(entityMenusAndUserData).subscribe(({
			dispatchers,
			locations,
			selectedEntity,
		}) => {
			if (selectedEntity && selectedEntity.entityType) {
				this.currentToggleType = selectedEntity.entityType as ToggleType;
				this.selectedEntity = selectedEntity;
			}
			if ((!this.dispatchers || this.dispatchers.length === 0) || (!this.locations || this.locations.length === 0)) {
				this.dispatchers = Array.isArray(dispatchers) && dispatchers?.length ? dispatchers : [];
				this.locations = Array.isArray(locations) && locations?.length ? locations : [];
				this.dispatcherLocationFormGroup.controls['listedValue'].setValue(this.selectedEntity?.value || '', { emitEvent: false });
				this.toggleChecked = this.currentToggleType === 'dispatcherId';
				this.filteredResults = this.toggleChecked ? this.dropDownFilteredOptions(this.dispatchers) : this.dropDownFilteredOptions(this.locations);
			}
		});
	}

	ngOnInit() {
		this.dispatcherLocationFormGroup.valueChanges.pipe(map(data => {
			let selectedValue;
			if (this.locations && this.currentToggleType === 'locationId') {
				selectedValue = this.locations.filter((loc) => loc.id === data.listedValue);
			};
			if (this.dispatchers && this.currentToggleType === 'dispatcherId') {
				selectedValue = this.dispatchers.filter((loc) => loc.id === data.listedValue);
			}
			if (selectedValue?.length > 0) {
				this.store.dispatch(setSelectedEntity({
					label: selectedValue[0].label,
					value: selectedValue[0].id,
					entityType: this.currentToggleType,
				}));
			}
		})).subscribe();
	}

	toggleSelection(change: MatSlideToggleChange) {
		this.toggleChecked = change.checked;
		this.currentToggleType = this.toggleChecked ? 'dispatcherId' : 'locationId';
		this.filteredResults = this.toggleChecked ? this.dropDownFilteredOptions(this.dispatchers) : this.dropDownFilteredOptions(this.locations);
		if (this.selectedEntity && this.selectedEntity.value && this.selectedEntity.entityType !== this.currentToggleType) {
			this.dispatcherLocationFormGroup.controls['listedValue'].setValue('', { emitEvent: false });
		} else {
			this.dispatcherLocationFormGroup.controls['listedValue'].setValue(this.selectedEntity?.value || '', { emitEvent: false });
		}
	}

	public dropDownFilteredOptions(allArray) {
		if (allArray) {
			return allArray.map((opt) => ({ ...opt, title: opt.label, value: opt.id }));
		}
		return [];
	}

}
