import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoAlertComponent } from './info-alert.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
	],
	exports: [
		InfoAlertComponent,
	],
	providers: [
	],
	declarations: [
		InfoAlertComponent,
	],
})
export class InfoAlertModule { }
