import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { PipesModule } from 'app/pipes/pipes.module';
import { MaterialModule } from '../../material/material.module';
import { DialogComponent } from './dialog.component';

@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
		MatButtonModule,
		PipesModule,
	],
	exports: [
		DialogComponent,
	],
	providers: [
	],
	declarations: [
		DialogComponent,
	],
})
export class DialogModule { }
