export const DIVISION_TABLE_HEADERS = [
	'Divisions',
	'Drive Hours Available',
	'On Hours Available',
	'Weekly Hours Available',
];

export const BY_DRIVER_TABLE_HEADERS = [
	'Drivers',
	'Last Location',
	'Drive Hours Available',
	'On Hours Available',
	'Weekly Hours Available',
];

export const BY_DRIVER_TABLE_DISPLAYED_COLUMNS = [
	'driver_name',
	'last_location',
	'avail_d',
	'avail_on',
	'duty_period',
];

export const DISPLAYED_COLUMNS = [
	'divisionName',
	'avail_d',
	'avail_on',
	'duty_period',
];

export const CARD_COLUMNS = [
	'driver_name',
	'avail_d',
	'avail_on',
	'duty_period',
];

export const CARD_HEADERS = [
	'Driver',
	'Drive Hours',
	'On Hours',
	'Duty Period',
];
