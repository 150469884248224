import { HosTableCellType, HosTableDataSource, HosTableColumn } from '@zonar-ui/hos-components';
import { HumanizeDurationPipe } from 'app/pipes/humanize-duration.pipe';
import { MalfunctionsService } from 'app/reports/malfunctions/malfunctions.service';
import {BehaviorSubject, EMPTY, Subject, Subscription} from 'rxjs';
import {catchError, finalize, map, takeUntil} from 'rxjs/operators';
import { formatTime } from '../../utils/time-utils';
import {AssetMalfunctions, Malfunction } from './malfunction-types';

export const malfunctionsColumns: HosTableColumn[] = [
  {
    columnDef: 'currentDriver', header: 'LAST KNOWN DRIVER', sortable: false,
    cell: (row: Malfunction) => row.currentDriver || 'Unknown',
    type: HosTableCellType.Custom,
  },
  {
    columnDef: 'assetNumber', header: 'ASSET #', sortable: true,
    cell: (row: Malfunction) => row.assetNumber || 'Unknown',
    type: HosTableCellType.Text
  },
  {
    columnDef: 'startTime', header: 'START DATE/TIME', sortable: true,
    cell: (row: Malfunction) => formatTime(row.startTime, row.timezone) || 'Unknown',
    type: HosTableCellType.Text,
    columnStyle: { flex: '0 0 12%' }
  },
  {
    columnDef: 'duration', header: 'DURATION', sortable: true,
    cell: (row: Malfunction) => new HumanizeDurationPipe().transform(row.malfunctionPeriod, 'ms'),
    type: HosTableCellType.Text,
  },
  {
    columnDef: 'displayMalfunctionType', header: 'TYPE', sortable: true,
    cell: (row: Malfunction) => row.displayMalfunctionType,
    type: HosTableCellType.Custom
  },
  {
    columnDef: 'malfunction_driver_name', header: 'IMPACTED DRIVERS', sortable: false,
    cell: (row: Malfunction) => row.malfunctionDriverName || 'Unknown',
    type: HosTableCellType.Custom
  },
  {
    columnDef: 'divisionName', header: 'DRIVER HOME LOCATION', sortable: true,
    cell: (row: Malfunction) => row.mostRecentDriver?.location,
    type: HosTableCellType.Text
  },
  {
    columnDef: 'endTime', header: 'STATUS', sortable: false, cell: (row: any) => {
      return row.endTime ? `Cleared - ${formatTime(row.endTime, row.timezone) || 'date not known'}` : 'Active';
    },
    type: HosTableCellType.Text,
    columnStyle: { flex: '0 0 15%' }
  },
  {
    columnDef: 'footerText', header: '', sortable: false,
    cell: (row: Malfunction) => '',
    type: HosTableCellType.Text,
    columnStyle: { display: 'none' }
  }
];

export class MalfunctionsDataSource implements HosTableDataSource {
  public malfunctionsDataSource: BehaviorSubject<any[]> = new BehaviorSubject([]);

  total$ = new BehaviorSubject(0);

  loading$ = new BehaviorSubject(true);

  destroy$ = new Subject<boolean>();

  testErrorMessage = '';

  errorObj$ = new BehaviorSubject({msg: null, status: null});

  defaultStateEnabled = new BehaviorSubject<boolean>(false);

  data = [];

  /* eslint-disable @typescript-eslint/naming-convention */
  currentParams = {
  	page: 1,
  	per_page: 20,
    limit: 20,
    offset: 0,
  	sort_by: undefined,
    sort_direction: undefined
  };

  public subs: Subscription[] = [];

  constructor(private _malfunctionService: MalfunctionsService, params?) {
    this.currentParams = {
      ...params,
      ...this.currentParams
    };
  }

  connect() {
    this.loading$.next(true);
    return this.malfunctionsDataSource.asObservable();
  }

  disconnect(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.malfunctionsDataSource.complete();
  }

  filtersChanged(filters: any) {
    const newFilters = {
      limit: this.currentParams.per_page,
      offset: Math.abs(this.currentParams.page - 1) * this.currentParams.per_page,
      page: this.currentParams.page,
  	  per_page: this.currentParams.per_page,
      sort_by: this.currentParams.sort_by,
      sort_direction: this.currentParams.sort_direction,
      ...filters
    }
    this.currentParams = newFilters;
    this.loadData();
  }

  loadData(params?: any) {
    this.updateLimitAndOffset(params);
    if (params && params.sort) {
      const values = params.sort.split(':');
      this.currentParams.sort_by = values[0];
      if (this.currentParams.sort_by === 'displayMalfunctionType') {
        this.currentParams.sort_by = 'malfunctionType'
      }
      this.currentParams.sort_direction = values[1]
    }

    this.loading$.next(true);
    this.malfunctionsDataSource.next([]);
    this.errorObj$.next({msg: null, status: null});
    this._malfunctionService.getMalfunctions({
      ...this.currentParams
    }).pipe(
        takeUntil(this.destroy$),
        map((res: { data: Malfunction[], total: any }) => {
      this.malfunctionsDataSource.next(res.data);
      this.data = res.data;
      this.total$.next(res.total);
    }),
      finalize(() => this.loading$.next(false)),
      catchError(err => {
        this.malfunctionsDataSource.next([]);
        this.total$.next(0);
        this.errorObj$.next(err);
        return EMPTY;
      })).subscribe();

    return;
  }

  private updateLimitAndOffset(params) {
    if(params) {
      if(params.page !== undefined && params.per_page) {
        this.currentParams.limit = params.per_page,
        this.currentParams.offset = Math.abs(params.page - 1) * params.per_page;
        this.currentParams.per_page = params.per_page;
        this.currentParams.page = params.page;
      }
    }
  }

}
