import {
	animate, style, transition, trigger,
} from '@angular/animations';
import {
	Component, ContentChildren, ElementRef,
	EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, Renderer2,
} from '@angular/core';

export type Position = 'right' | 'left';

@Component({
	animations: [
		trigger('panelState', [
			transition('void => visible', [
				style({ transform: '{{transform}}', opacity: 0 }),
				animate('{{transition}}'),
			]),
			transition('visible => void', [
				animate('{{transition}}', style({ transform: '{{transform}}', opacity: 0 })),
			]),
		]),
	],
	selector: 'z-side-bar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    private _opened = false;

    private _position: Position = 'right';

    @Input() get opened(): boolean {
    	return this._opened;
    }

    set opened(val: boolean) {
    	this._opened = val;
    }

    @Input()
    set position(pos: Position) {
    	this._position = pos;
    	switch (pos) {
    	case 'left':
    		this.transformOptions = 'translate3d(-100%, 0px, 0px)';
    		break;
    	default:
    		this.transformOptions = 'translate3d(100%, 0px, 0px)';
    		break;
    	}
    }

    get position(): Position {
    	return this._position;
    }

    @Input() transitionOptions = '150ms cubic-bezier(0, 0, 0.2, 1)';

    @Input() dismissible = true;

    public transformOptions = 'translate3d(100%, 0px, 0px)';

    @ContentChildren(HTMLElement) templates: QueryList<HTMLElement>;

    @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

    private overlayElement: HTMLDivElement;

    private overlayElementClickListener: () => void;

    constructor(private elementRef: ElementRef, private renderer2: Renderer2) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    	if (this.overlayElementClickListener) {
    		this.overlayElementClickListener();
    	}
    }

    onAnimationStart(event) {
    	if (event.toState === 'visible') {
    		this.bindDocumentClick();
    	}
    }

    bindDocumentClick() {
    	if (!this.overlayElement) {
    		this.overlayElement = document.createElement('div');
    		this.overlayElement.style.zIndex = '1007'; // One point less to the Sidebar zIndex (1008)
    		this.overlayElement.className = 'z-sidebar-overlay';

    		if (this.dismissible) {
    			this.overlayElementClickListener = this.renderer2.listen(this.overlayElement, 'click', (event: Event) => {
    				if (this.dismissible) {
    					this.closeSideBar();
    				}
    			});
    		}
    		document.body.appendChild(this.overlayElement);
    	}
    }

    closeSideBar() {
    	if (this.overlayElementClickListener) {
    		this.overlayElementClickListener();
    	}
    	if (this.overlayElement) {
    		document.body.removeChild(this.overlayElement);
    	}
    	this.overlayElement = null;
    	this.opened = false;
    	this.closed.emit(this.opened);
    }
}
