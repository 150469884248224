const DateRangePreSets = [
    { title: 'Previous 7 days', value: 'previous-7-days', numValue: 7 },
    { title: 'Previous 14 days', value: 'previous-14-days', numValue: 14 },
    { title: 'Previous 30 days', value: 'previous-30-days', numValue: 30 },
    { title: 'Previous 3 months', value: 'previous-90-days', numValue: 89 },
    { title: 'Previous 6 months', value: 'previous-180-days', numValue: 179 },
    { title: 'Custom', value: 'custom', numValue: undefined },
];

export const DateRangeWithPreviousDay = [
    { title: 'Previous 24hrs', value: 'previous-1-days', numValue: 1 },
].concat(DateRangePreSets);

export const DateRangeWithToday = [
    { title: 'Today', value: 'today', numValue: 0 },
].concat(DateRangePreSets);

export const DriverSearchTabs = [{
    tableName: 'Drivers',
    displayTableName: 'Drivers',
    queryParamName: 'driver_id'
}];
