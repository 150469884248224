import {
	Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';

@Component({
	selector: 'table-with-sparkline',
	templateUrl: './multi-table.component.html',
	styleUrls: ['./multi-table.component.scss'],
})
export class MultiTableComponent {
	@Input() isEntitySelected: boolean;

	@Input() showSkeleton: boolean = false;

	@Input() isLocationSelected;

	@Input() showLoading: boolean = true;

	@Input() tableData: any;

	@Input() malfunctionsTableDialogData: any;

	@Input() pageSizeOptions = [30, 60, 120];

	@Output() divisionClick: EventEmitter<any> = new EventEmitter<any>();

	@Output() driverClick: EventEmitter<any> = new EventEmitter<any>();

	constructor() {
	}

	divisionClicked(event) {
		this.divisionClick.emit(event);
	}

	driverClicked(event) {
		this.driverClick.emit(event);
	}
}
