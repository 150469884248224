import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker-ext';
import { ReactiveFormsModule } from '@angular/forms';
import { HighchartModule } from 'app/components/highchart/highchart.module';
import { RouterModule } from '@angular/router';
import { DataTableModule } from '../../components/data-table/data-table.module';
import { MaterialModule } from '../../material/material.module';
import { MapMetricTileModule } from '../../components/map-metric-tile/map-metric-tile.module';
import { MapModule } from '../../components/map/map.module';

import { MultiTableComponent } from './multi-table/multi-table.component';
import { SearchableFilterComponent } from './searchable-filter/searchable-filter.component';
import { SingleSelectFilterComponent } from './single-select-filter/single-select-filter.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { KpiComponent } from './kpi/kpi.component';
import { ChartComponent } from './chart/chart.component';
import { FiltersComponent } from './filters/filters.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import {CsvExportComponent} from './csv-export/csv-export.component';
import {ZonarUiNotificationsModule} from '@zonar-ui/notifications';
import {ErrorMessageComponent} from './error-message/error-message.component';
import { ReportTitleComponent } from './report-title/report-title.component';
import { ShowMultipleListDialogComponent } from './show-multiple-list-dialog/show-multiple-list-dialog';

export {
	MultiTableComponent,
};

@NgModule({
	imports: [
		MapMetricTileModule,
		DataTableModule,
		CommonModule,
		RouterModule,
		HighchartModule,
		SatDatepickerModule,
		SatNativeDateModule,
		MaterialModule,
		MapModule,
		ReactiveFormsModule,
		ZonarUiNotificationsModule,
	],
	exports: [
		MultiTableComponent,
		SearchableFilterComponent,
		DateRangeFilterComponent,
		SingleSelectFilterComponent,
		KpiComponent,
		ChartComponent,
		SubHeaderComponent,
		FiltersComponent,
		CsvExportComponent,
		ErrorMessageComponent,
		ReportTitleComponent,
		ShowMultipleListDialogComponent,
	],
	providers: [],
	declarations: [
		MultiTableComponent,
		SearchableFilterComponent,
		SingleSelectFilterComponent,
		KpiComponent,
		DateRangeFilterComponent,
		FiltersComponent,
		SubHeaderComponent,
		ChartComponent,
		CsvExportComponent,
		ErrorMessageComponent,
		ReportTitleComponent,
		ShowMultipleListDialogComponent,
	],
})
export class SharedReportsComponentsModule {
}
