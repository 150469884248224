<div class='dispatcher-contain'>
	<div *ngIf="dispatchers && dispatchers.length" class="dispatcher-list">
		<div class="autocomplete-wrapper dispatcher-autocomplete" [ngClass]="{'showCritical': !(selectedEntity && selectedEntity.value)}">
			<span class="toggle_id" [ngStyle]="{'opacity': !toggleChecked ? '1' : '0.35'}">Location</span>
			<mat-slide-toggle color="accent" (change)="toggleSelection($event)" [disabled]="false"
			  [checked]="toggleChecked" [aria-label]="'Toggle to either filter by location or dispatcher'">
			</mat-slide-toggle>
			<span class="toggle_id" [ngStyle]="{'opacity': toggleChecked ? '1' : '0.35'}">Dispatcher</span>
			<zui-searchable-dropdown
					label="{{currentToggleType === 'dispatcherId' ? 'DISPATCHER*': 'LOCATION*'}}"
					placeholder="{{currentToggleType === 'dispatcherId' ? 'Enter Dispatcher Name': 'Enter Location Name'}}"
					[options]="filteredResults"
					[z_formGroup]="dispatcherLocationFormGroup"
					[isMultiple]="false"
					[selectOptionsControlName]="'listedValue'"
					[searchFormControlName]="'dispatcherLocationId'"
					classIdentifier="dropdown-select-panel"
			></zui-searchable-dropdown>
		</div>
	</div>
</div>
