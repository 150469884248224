import { Action } from '@ngrx/store';
import { Params } from '@angular/router';
import { AvailabilityDetails } from './availability.types';

export const GET_DRIVER_AVAILABILITY = '[Get] GET_DRIVER_AVAILABILITY';
export const REFRESH_DRIVER_AVAILABILITY = '[Get] REFRESH_DRIVER_AVAILABILITY';
export const GET_DRIVER_AVAILABILITY_SUCCESS = '[Set] GET_DRIVER_AVAILABILITY_SUCCESS';
export const GET_DRIVER_AVAILABILITY_FAILED = '[Get] GET_DRIVER_AVAILABILITY_FAILED';

export class GetDriverAvailability implements Action {
	readonly type = GET_DRIVER_AVAILABILITY;

	constructor() {}
}
export class RefreshDriverAvailability implements Action {
	readonly type = REFRESH_DRIVER_AVAILABILITY;

	constructor(public payload: Params) {}
}
export class GetDriverAvailabilitySuccess implements Action {
	readonly type = GET_DRIVER_AVAILABILITY_SUCCESS;

	constructor(public payload: AvailabilityDetails) {}
}

export class GetDriverAvailabilityFailed implements Action {
	readonly type = GET_DRIVER_AVAILABILITY_FAILED;

	constructor(public payload: any) {}
}

export type Actions =
	| GetDriverAvailability
	| RefreshDriverAvailability
	| GetDriverAvailabilitySuccess
	| GetDriverAvailabilityFailed;
