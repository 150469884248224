import { Action, createReducer, on } from '@ngrx/store';
import { HoursState } from './hours.types';
import { initialHoursState } from './hours.utils';

import * as hoursActions from './hours.actions';
import {errorMessage} from '../../reports/shared-report-components/error-message/error-message';
import {errorObject} from 'rxjs/internal-compatibility';

export { HoursState } from './hours.types';
export { initialHoursState } from './hours.utils';

export const hoursFeatureKey = 'hours';

export const hoursReducer = createReducer(
	initialHoursState,
	on(hoursActions.loadHours, (state) => ({
		...state,
		isLoading: true,
		isLoadSuccess: false,
		errorObj: {message: '', name: '', statusText: ''}
	})),
	on(hoursActions.loadHoursFilters, (state) => ({
		...state,
		isLoading: true,
		isLoadSuccess: false,
		errorObj: {message: '', name: '', statusText: ''}
	})),
	on(hoursActions.loadHoursFiltersSuccess, (state, dat) => ({
		...state,
		filterOptions: {
			...state.filterOptions,
			...dat.filters,
		},
		isLoading: true,
		isLoadSuccess: false,
		errorObj: {message: '', name: '', statusText: ''},
		isError: false,
	})),
	on(hoursActions.loadHoursRange, (state) => ({
		...initialHoursState,
		isLoading: true,
		isLoadSuccess: false,
	})),
	on(hoursActions.filterHours, (state) => ({
		...state,
		isLoading: true,
		isLoadSuccess: false,
	})),
	on(hoursActions.loadHoursSuccess, (state, hours) => ({
		...state,
		...hours,
		isLoadSuccess: true,
		isLoading: false,
		isError: false,
		errorObj: {message: '', name: '', statusText: ''}
	})),
	on(hoursActions.loadHoursFailure, (state, error) => ({
		...state,
		isLoadSuccess: false,
		isLoading: false,
		isError: true,
		errorObj: {error}
	}))

);

export function HoursReducer(state: HoursState | undefined, action: Action) {
	return hoursReducer(state, action);
}
