import {
	Actions,
	GET_VIOLATIONS_LIVE_STATUS,
	GET_VIOLATIONS_LIVE_STATUS_SUCCESS,
} from './hos-violations.actions';
import {HosViolationsLiveStatusState,
} from '../../reports/hos-violations/hos-violation-types';

export const initialViolationsLiveStatusState = {
	driversInViolation: [],
	driversAtRisk: [],
	isLoading: false,
	isLoadSuccess: false,
};

export function hosViolationsLiveStatusReducer(state = initialViolationsLiveStatusState, action: Actions): HosViolationsLiveStatusState {
	switch(action.type) {
		case GET_VIOLATIONS_LIVE_STATUS_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case GET_VIOLATIONS_LIVE_STATUS:
			return {
				...state,
			};
		default:
			return state;
	}
}
