import {
	animate, sequence, style, transition, trigger, state
} from '@angular/animations';

export const fadeInSlideUp =	trigger('fadeInSlideUp', [
	transition('void => *', [
		style({
			height: '*', opacity: '0', transform: 'translatey(-40px)', 'box-shadow': 'none',
		}),
		sequence([
			animate('.1s ease', style(
				{
					height: '*', opacity: '1', transform: 'translatey(0)', 'box-shadow': 'none',
				},
			)),
		]),
	]),
]);

export const rowsAnimation =	trigger('rowsAnimation', [
	transition('void => *', [
		style({
			height: '*', opacity: '0', transform: 'translatey(-20px)', 'box-shadow': 'none',
		}),
		sequence([
			animate('0.1s ease', style(
				{
					height: '*', opacity: '1', transform: 'translatey(0)', 'box-shadow': 'none',
				},
			)),
		]),
	]),
]);

export const rotateChevron = trigger('rotateChevron', [
	state('true',
		style({
			transform: 'rotate(180deg)'
		})
	),
	state('false',
		style({
			transform: 'rotate(0deg)'
		})
	),
	transition('true <=> false', animate('200ms ease-out')),
]);
