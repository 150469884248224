import { createReducer, on } from '@ngrx/store';
import { initialOdometerState } from './odometer.utils';
import * as odometerActions from './odometer.actions';

export const odometerFeatureKey = 'odometer';

export const odometerReducer = createReducer(
	initialOdometerState,
	on(odometerActions.loadOdometers, (state) => ({
		...state,
		isLoading: true,
		isLoadSuccess: false,
	})),
	on(odometerActions.clearOdometers, (state) => ({
		...initialOdometerState,
	})),
	on(odometerActions.loadOdometersSuccess, (state, odometer) => ({
		...state,
		...odometer,
		isLoadSuccess: true,
		isLoading: false,
	})),
);
