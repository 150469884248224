import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { LocalStorageService } from 'app/local-storage.service';

@Component({
	selector: 'log-out',
	template: '',
})
export class LogoutComponent implements OnInit {
	constructor(public authService: AuthService,
		private localStorageService: LocalStorageService,
		@Inject('logoutReturnToUrl') private readonly logoutReturnToUrl: string,) {
	}

	ngOnInit() {
		this.localStorageService.removeAll();
		this.authService.logout({returnTo: this.logoutReturnToUrl});
	}
}
