import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../state/app/app.service';
import { zeroRefreshMs } from '../../state/app/app.actions';
import { GetLiveStatus } from '../../state/live-status/live-status.actions';
import {GetHosViolationsLiveStatus} from "../../state/hos-violations/hos-violations.actions";

@Component({
	selector: 'info-alert',
	templateUrl: './info-alert.component.html',
	styleUrls: ['./info-alert.component.scss'],
})
export class InfoAlertComponent implements OnInit {
	@Input() minutesSinceRefresh: number;

	@Input() hideRefreshBar: boolean;

	constructor(
		public appService: AppService,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
	}

	//todo is this needed now?
	startRefresh() {
		this.appService.refreshDataOnce([
			zeroRefreshMs(),
		]);
	}
}
