import { Injectable } from "@angular/core";


export const ACTIVE_ACCOUNT_PARAM = 'activeAccount';
export const SELECTED_ENTITY = 'selectedEntity';
export const SELECTED_DISPATCHER_ID = 'dispatcherId';
export const SELECTED_LOCATION_ID = 'locationId';

type LocalStorageKey = 'activeAccount' | 'selectedEntity';

@Injectable({ providedIn: "root" })
export class LocalStorageService {

    constructor() {
    }

    set(key: LocalStorageKey, val: any) {
        if (key === SELECTED_ENTITY) {
            if (typeof val !== 'object') {
                throw Error('Invalid selection');
            }
            val = JSON.stringify(val);
        }
        localStorage.setItem(key, val);
    }

    get(key: LocalStorageKey): any {
        if (key === SELECTED_ENTITY) {
            try {
                return JSON.parse(localStorage.getItem(key));
            } catch (error) {
                console.log(error);
                return null;
            }
        }
        const val = localStorage.getItem(key);
        return (val && val !== 'null' && val !== 'undefined' && val.trim().length > 0) ? val : null;
    }

    getDispatcherId(): string {
        return this.getSelectedEntityVal(SELECTED_DISPATCHER_ID);
    }

    getLocationId(): string {
        return this.getSelectedEntityVal(SELECTED_LOCATION_ID);
    }

    getSelectedEntityVal(type: any): string {
        if (!this.exists(SELECTED_ENTITY)) {
            return '';
        }
        const selectedEntity = this.get(SELECTED_ENTITY);
        return selectedEntity.entityType === type ? selectedEntity.value : '';
    }

    isSelectedEntityExists(): boolean {
        return !!this.getDispatcherId() || !!this.getLocationId();
    }

    exists(key: LocalStorageKey): boolean {
        return this.get(key) !== null;
    }

    remove(key: LocalStorageKey) {
        localStorage.removeItem(key);
    }

    removeAll() {
        localStorage.clear();
    }
}