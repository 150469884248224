import { createAction, props } from '@ngrx/store';

export const loadOdometers = createAction(
	'[Odometer] Load Odometers',
);

export const clearOdometers = createAction(
	'[Odometer] Clear Odometers',
);

export const loadOdometersRange = createAction(
	'[Odometer] Load Odometers Range',
);

export const filterOdometers = createAction(
	'[Odometer] Filter Odometers',
);

export const loadOdometersSuccess = createAction(
	'[Odometer] Load Odometers Success',
	props<{ data: any }>(),
);

export const loadOdometersFailure = createAction(
	'[Odometer] Load Odometers Failure',
	props<{ error: any }>(),
);
