import {
	Component, Input, OnInit,
} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { catchError, finalize, map } from 'rxjs/operators';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { MalfunctionsService } from '../malfunctions.service';
import { MalfunctionDefinitions, MalfunctionTypeData } from '../malfunction-types';
import {ErrorObject} from "../../models";

@Component({
	selector: 'malfunctions-kpi',
	templateUrl: './malfunctions-kpi.component.html',
	styleUrls: ['./malfunctions-kpi.component.scss'],
})
export class MalfunctionsKpiComponent implements OnInit {

	private _queryParams = {};

	kpiCardTitle = 'As of Today';

	@Input() report = 'hos-malfunctions';

	@Input()
	public showMetricCard = true;

	@Input()
	public showViewDetailLink = true;

	@Input()
	set queryParams(params: any) {
		this._queryParams = params;
		if (this._queryParams && Object.keys(this._queryParams).length > 0) {
			this.getKpiData();
		}
	}

	get queryParams(): any {
		return this._queryParams;
	}

	routerLink = ['/malfunctions', 'details'];

	kpiData = {
		foundMalfunctions: 0,
		impactedAssets: 0,
		impactedDrivers: 0,
		malfunctionTypes: [],
		topMalfunctionType: '',
		totalAssets: 0,
		totalDrivers: 0,
		totalMalfunctions: 0,
		affectedDrivers: 0,
		affectedAssets: 0,
		chartSeriesData: { chart: [] }
	};

	errMessage$ = new BehaviorSubject<ErrorObject>(null);

	isLoading$ = new BehaviorSubject<boolean>(false);


	constructor(public route: ActivatedRoute,
		private _malfunctionsService: MalfunctionsService,
		private router: Router,) {

	}

	ngOnInit(): void {
		this.kpiCardTitle = this.showMetricCard ? 'Applying table filters' : this.kpiCardTitle;
	}

	getKpiData() {
		this.errMessage$.next(null);
		let topMalfunctionType = 'None';
		this.isLoading$.next(true);
		this._malfunctionsService.getMalfunctionsKpi(this.queryParams).pipe(map((res) => {
			if (res.malfunctionTypes && res.malfunctionTypes.length > 0) {
				const topMalfunction = res.malfunctionTypes.reduce((a, b) => a.count > b.count ? a : b);
				topMalfunctionType = res.foundMalfunctions ? MalfunctionDefinitions[topMalfunction.id] : 'None';
			}
			res.affectedDrivers = Math.round((res.impactedDrivers / res.totalDrivers) * 100) || 0;
			res.affectedAssets = Math.round(res.impactedAssets / res.totalAssets * 100) || 0;
			this.kpiData = {
				...res,
				topMalfunctionType: topMalfunctionType,
				chartSeriesData: this.createChartSeriesData(res.malfunctionTypes)
			};
		}),
			catchError(err => {
				if (err.error && err.error.message) {
					const message = err?.error?.message || err?.message || err?.msg || '';
					const detail = err?.error?.details || err?.details || null;
					const status = err?.error?.status || err?.status || null;
					this.errMessage$.next({error: {message: message || null, details: detail || null},
						status: status || null} as ErrorObject);
				};
				return EMPTY;
			})
			, finalize(() => {
				this.isLoading$.next(false);
			})).subscribe();
	}

	public createChartSeriesData(mfTypes: MalfunctionTypeData[]) {
		const dimmedColor = 'rgba(0,116,205,0.20)';
		const solidColor = 'rgba(0,116,205,0.50)';
		const chartColumnData = [
			{ id: 'POWER', count: 0, color: dimmedColor },
			{ id: 'DATA_TRANSFER', count: 0, color: dimmedColor },
			{ id: 'ENGINE_SYNCH', count: 0, color: dimmedColor },
			{ id: 'POSITIONING', count: 0, color: dimmedColor },
			{ id: 'DATA_RECORDING', count: 0, color: dimmedColor },
			{ id: 'TIMING', count: 0, color: dimmedColor }];
		if (mfTypes && mfTypes.length > 0) {
			mfTypes.map((mf) => {
				chartColumnData.map(a => {
					if (mf.id === a.id) {
						a.count = mf.count;
						a.color = solidColor;
					}
				})
			});
		}
		let newDataArray = [];
		let newColorArray = [];
		chartColumnData.map((a) => {
			newDataArray.push([a.count]);
			newColorArray.push(a.color);
		});
		return {
			metric: 'malfunctions',
			chart: [
				{
					name: 'Malfunctions',
					type: 'column',
					colors: newColorArray,
					colorByPoint: true,
					data: newDataArray,
				}
			]
		}
	};
}
