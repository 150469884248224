import { OptionList } from 'app/utils/options';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {HoursState, HoursRow, HoursDriverRow, V2HoursHttpResponse, DutyStatus, ChartData} from './hours.types';
import { Sds, DutyStatusesEntity } from '../sds/sds.types';

export const initialHoursState: HoursState = {
	isLoading: false,
	isLoadSuccess: false,
	totalNumberOfDrivers: 0,
	offset: 0,
	limit: 25,
	hoursData: [],
	tableData: {
		rows: [],
		displayedColumns: [
			'driverName',
			'date',
			'onHours',
			'offHours',
			'driveHours',
			'sbHours',
			'onDriveHours',
			'exsid',
		],
		headers: ['Driver', 'Date', 'On Duty Hours', 'Off Duty Hours', 'Drive hours', 'SB Hours', 'On Duty + Drive', 'EXSID'],
	},
	tableDataByDriver: {
		rows: [],
		displayedColumns: [
			'driverName',
			'onHours',
			'offHours',
			'driveHours',
			'sbHours',
			'onDriveHours',
			'exsid',
			'arrow',
		],
		headers: ['Driver', 'On Duty Hours', 'Off Duty Hours', 'Drive hours', 'SB Hours', 'On Duty + Drive', 'EXSID'],
	},
	chartData: {},
	filterOptions: {
		driverOptions: { options: [], selectedOption: {} },
		exsidOptions: { options: [], selectedOption: {} },
		tagidOptions: { options: [], selectedOption: {} },
		rulesetOptions: { options: [], selectedOption: {} },
		locationOptions: { options: [], selectedOption: {} },
	},
	isError: false,
	errorObj: {}
};

const emptyRow = {
	driverName: undefined,
	date: undefined,
	onHours: 0,
	offHours: 0,
	driveHours: 0,
	sbHours: 0,
	onDriveHours: 0,
	exsid: undefined,
};

const emptyGroupByRow = {
	driverName: undefined,
	driverId: undefined,
	date: undefined,
	onHours: 0.00,
	offHours: 0.00,
	driveHours: 0.00,
	sbHours: 0.00,
	onDriveHours: 0,
	exsid: undefined,
	hoursEvents: [],
	averageOnHours: 0.00,
	averageOffHours: 0.00,
	averageSBHours: 0.00,
	averageDriveHours: 0.00,
}

export class HoursUtils {

	populateDataModel(hoursState: HoursState, params): HoursState {
		let rulesetOptions = this.makeEmptyOptionsList('All Rulesets', 'ruleset');

		const model: HoursState = {
			...hoursState,
			tableData: {
				...initialHoursState.tableData,
				rows: [],
			},
			tableDataByDriver: {
				...initialHoursState.tableDataByDriver,
				rows: [],
			},
			chartData: {
				OFF: 0,
				ON: 0,
				DRIVE: 0,
				SB: 0,
			},
			filterOptions: {
				...hoursState.filterOptions,
				rulesetOptions,
			},
		};
		_.forEach(hoursState.hoursData, (driver: Sds) => {
			const eventSummary = driver.summary;
			const beginDate = params.beginDate
				? moment(params.beginDate).tz(driver.timezone).startOf('day') : moment.tz(driver.timezone).subtract(13, 'days').startOf('day');

			const endDate = params.endDate
				? moment.tz(params.endDate, driver.timezone).endOf('day') : moment.tz(driver.timezone).endOf('day');
			let dateBuckets = this.makeDateRangeObject(beginDate, endDate);

			if (!_.isEmpty(eventSummary) && eventSummary.duty_statuses.length > 0) {
				_.forEach(eventSummary.duty_statuses, (status) => {
					if (status.event_code > 0 && status.event_code <= 4) {
						dateBuckets = this.calcDateBuckets(status, dateBuckets, driver.timezone);
					}
				});

				const exsid = driver.exsid ? driver.exsid.toString() : '';
				const tagId = driver.tagid ? driver.tagid.toString() : '';
				const ruleset = driver.summary.ruleset.toString();
				rulesetOptions = this.addOption(ruleset, params.ruleset, rulesetOptions, 'ruleset', driver.summary.ruleset_name);


				const driverRow: HoursDriverRow = {
					driverName: driver.name,
					driverHomeLocation: driver.driver_location_id,
					onHours: '0.00',
					offHours: '0.00',
					driveHours: '0.00',
					sbHours: '0.00',
					onDriveHours: '0.00',
					hoursEvents: [],
					driverId: driver.driver.toString(),
					ruleset: driver.summary.ruleset,
					ruleset_name: driver.summary.ruleset_name,
					tagid: tagId,
					exsid,
					averageOnHours: '0.00',
					averageOffHours: '0.00',
					averageSBHours: '0.00',
					averageDriveHours: '0.00',
				};

				_.map(eventSummary.duty_status_totals, (total: { seconds: number, event_code: number }) => {
					const event_code_types = {
						1: ['offHours', 'averageOffHours'],
						2: ['sbHours', 'averageSBHours'],
						3: ['driveHours', 'averageDriveHours'],
						4: ['onHours', 'averageOnHours'],
					};
					if (event_code_types[total.event_code]) {
						driverRow[event_code_types[total.event_code][0]] = (total.seconds / 3600).toFixed(2).toString();
						driverRow[event_code_types[total.event_code][1]] = ((total.seconds / (endDate.diff(beginDate, 'days') + 1)) / 3600).toFixed(2);
					}
				});

				driverRow.onDriveHours = (Number(driverRow.onHours) + Number(driverRow.driveHours)).toFixed(2).toString();
				if (this.filterData(driverRow, params)) {
					const dayRows = [];
					model.chartData.ON += Number(driverRow.onHours);
					model.chartData.OFF += Number(driverRow.offHours);
					model.chartData.SB += Number(driverRow.sbHours);
					model.chartData.DRIVE += Number(driverRow.driveHours);
					_.forEach(dateBuckets, (value, key) => {
						dayRows.push({
							driverName: driver.name,
							date: moment(Number(key)).tz(driver.timezone).format('MMM Do YYYY'),
							offHours: ((value['1'] || 0.00) / 3600).toFixed(2),
							sbHours: ((value['2'] || 0.00) / 3600).toFixed(2),
							driveHours: ((value['3'] || 0.00) / 3600).toFixed(2),
							onHours: ((value['4'] || 0.00) / 3600).toFixed(2),
							onDriveHours: (((value['3'] || 0.00) + (value['4'] || 0)) / 3600).toFixed(2),
							ruleset: driver.summary.ruleset,
							ruleset_name: driver.summary.ruleset_name,
							timezone: driver.timezone,
							csvDate: moment(Number(key)).tz(driver.timezone).format('MM/DD/YYYY'),
							exsid: driver.exsid,
						});
					});
					driverRow.hoursEvents = dayRows;
					model.tableData.rows = [...model.tableData.rows, ...dayRows];
					model.tableDataByDriver.rows.push(driverRow);
				}
			}
		});

		return model;
	}

	makeEmptyOptionsList(label: string, name: string, includeInOptions: boolean = false): OptionList {
		const selectedOption = { label, value: { name, code: '' } };
		return { options: includeInOptions ? [selectedOption] : [], selectedOption };
	}

	makeDateRangeObject(start: moment.Moment, end: moment.Moment) {
		const dutyStatusTypeBuckets = {
			1: 0, 2: 0, 3: 0, 4: 0,
		};
		const dateObject = { [start.startOf('day').valueOf()]: { ...dutyStatusTypeBuckets } };
		const dateItr = start.clone().startOf('day');
		while (dateItr.add(1, 'days').diff(end) <= 0) {
			dateObject[dateItr.valueOf()] = { ...dutyStatusTypeBuckets };
		}
		return dateObject;
	}

	calcDateBuckets(dutyStatus: DutyStatusesEntity, buckets, timezone) {
		const lowestDateInParams = moment(Math.min(...Object.keys(buckets).map((n) => Number(n)))).tz(timezone);
		const dsStart = moment(dutyStatus.start_epoch * 1000).tz(timezone);
		const dsEnd = moment(dutyStatus.end_epoch * 1000).tz(timezone);
		let currentDay = (dsStart.valueOf() < lowestDateInParams.valueOf() ? moment(lowestDateInParams) : dsStart);

		while (currentDay.clone().startOf('day').diff(dsEnd.clone().startOf('day'), 'day') <= 0) {
			const selector = currentDay.clone().startOf('day') as any;
			if (_.get(buckets, selector)) {
				const endOfDailyStatus = selector.diff(dsEnd, 'day') === 0 ? dsEnd : currentDay.clone().add(1, 'day').startOf('day');
				buckets[selector.valueOf()][dutyStatus.event_code] += endOfDailyStatus.diff(currentDay, 'seconds');
			}
			currentDay = currentDay.clone().add(1, 'day').startOf('day');
		}
		return buckets;
	}

	buildFilters(filters, params) {
		let locationOptions = this.makeEmptyOptionsList('All Home Locations', 'HomeLocation');
		let driverOptions = this.makeEmptyOptionsList('All Drivers', 'driverId');
		let exsidOptions = this.makeEmptyOptionsList('All Exsids', 'exsid');
		let tagidOptions = this.makeEmptyOptionsList('All Tagids', 'tagid');
		_.forEach(filters.drivers, (driver) => {
			driverOptions = this.addOption(driver.driver, params.driverId, driverOptions, 'driverId', `${driver.driver_first_name} ${driver.driver_last_name}`);
			if (driver.exsid) {
				exsidOptions = this.addOption(driver.exsid, params.exsid, exsidOptions, 'exsid', driver.exsid);
			}
			if (driver.tagid) {
				tagidOptions = this.addOption(driver.tagid, params.tagid, tagidOptions, 'tagid', driver.tagid);
			}
		});
		_.forEach(filters.locations, (location) => locationOptions = this.addOption(location.id, params.driverHomeLocation, locationOptions, 'driverHomeLocation', location.name));

		return {
			locationOptions,
			driverOptions,
			exsidOptions,
			tagidOptions,
		};
	}

	filterData(row, params) {
		let isMatch = true;
		_.map(params, (value: string | Array<string>, key: string) => {
			isMatch = (
				isMatch
				&& (
					row[key]
					&& value.includes(row[key].toString())
					|| !value
					|| value.length === 0
					|| value.includes('all')
					|| !(key in row)
				)
			);
		});
		return !!isMatch;
	}

	/** Add an option if missing
	@param code value of the field from a record
	@param selectedCode value of the field from the params
	@param dropDown the list of options, to be populated
	@param name the option name
	@param label the label for value from the record if different from the value itself
	*/
	addOption(code: string, selectedCode: string, dropDown: OptionList, name: string, label?: string): OptionList {
		if (code && !_.some(dropDown.options, (option) => (option.value.code === code))) {
			const newOption = {
				label: label || code,
				value: { name, code },
			};

			dropDown.options.push(newOption);
			if (_.includes(selectedCode, code)) {
				dropDown.selectedOption = newOption;
			}
		}
		return dropDown;
	}

	////////////////////////////////////  NEW FUNCTIONS  //////////////////////////////////////////////

	static reshapeForDataTableAndChartView(response: Array<V2HoursHttpResponse>) {
		let dataTable = [];
		// this is the final chart Data that is a cumulative of the daily chart data for every driver
		let accChartData: ChartData = {
			OFF: 0,
			ON: 0,
			DRIVE: 0,
			SB: 0,
		};
		response.forEach((data) => {
			if (data?.duty_status_totals) {
				let chartData = {};
				let driverName = `${data?.driver_info?.first_name} ${data?.driver_info?.last_name}`;
				let exsid = data?.driver_info?.exsid ? `${data?.driver_info?.exsid}` : 'Unknown';
				for (const [date, value] of Object.entries(data?.duty_status_totals)) {
					//close the empty row, populate values based on key map, and accumulative chart data
					// Only add duty status that do not have key, value pair of -1
					if (!value["-1"]) {
						let row;
						[row, chartData] = this.configTableRowAndChartData(date, value, driverName, exsid);
						accChartData = this.updateAccChartData(accChartData, chartData);
						dataTable.push(row);
					}
				}
			}
		});
		return [dataTable, accChartData];
	}

	static configTableRowAndChartData(date, dutyStatus, driverName?, exsid?) {
		let data = _.clone(emptyRow);
		let onHours = dutyStatus[DutyStatus.OnHours.toString()] || 0;
		let driveHours = dutyStatus[DutyStatus.DriveHours.toString()] || 0;
		let offHours = dutyStatus[DutyStatus.OffHours.toString()] || 0;
		let sbHours = dutyStatus[DutyStatus.SbHours.toString()] || 0;
		// chart data created for the day
		const chartData = {
				OFF: offHours,
				ON: onHours,
				DRIVE: driveHours,
				SB: sbHours,
		};

		if (driverName) {
			data.driverName = driverName;
		}
		if (exsid) {
			data.exsid = exsid;
		}
		data.date = moment(date).format('MM/DD/YYYY');
		data.offHours = this.convertSecondsToHours(offHours);
		data.onHours = this.convertSecondsToHours(onHours);
		data.sbHours = this.convertSecondsToHours(sbHours);
		data.driveHours = this.convertSecondsToHours(driveHours);
		data.onDriveHours = this.convertSecondsToHours(driveHours + onHours);
		return [data, chartData];
	}

	static convertSecondsToHours(secs): number {
		return +(secs / 3600).toFixed(2);
	}

	static forGroupByView(response: Array<V2HoursHttpResponse>, chartData?:ChartData) {
		let dataTable = [];
		let accChartData;
		// assigns accChartData to either current chartData or else a new ChartData type
		accChartData = !chartData?  {OFF: 0, ON: 0, DRIVE: 0, SB: 0} : chartData;
		response.forEach((data) => {
			if (data?.duty_status_totals) {
				let reformedChart;
				let d = _.clone(emptyGroupByRow);
				d.driverName = `${data?.driver_info?.first_name} ${data?.driver_info?.last_name}`;
				d.driverId = data?.driver_info.driver_id;
				d.exsid = data?.driver_info?.exsid ? `${data?.driver_info?.exsid}` : 'Unknown';
				let days = [];
				let totalOn = 0;
				let totalOff = 0;
				let totalSb = 0;
				let totalD = 0;
				let totalOnD = 0;
				let countOfEmptyDutyStatus = 0;
				for (const [date, value] of Object.entries(data?.duty_status_totals)) {
					// Only add duty status that do not have key, value pair of -1
					if (!value["-1"]) {
						let day;
						[day, reformedChart] = this.configTableRowAndChartData(date, value);
						// creates accumulative chart date for each day for each driver, fx is only called if the chartData does not exist
						if (!chartData) {
							accChartData = this.updateAccChartData(accChartData, reformedChart);
						}
						totalOn += day.onHours;
						totalOff += day.offHours;
						totalSb += day.sbHours;
						totalD += day.driveHours;
						totalOnD += day.onDriveHours;
						days.push(day);
					}
					// Counts the number of duty status that have key, value pair of -1
					if(value["-1"]) {
						countOfEmptyDutyStatus++;
					}
				}
				d.hoursEvents = days;
				d.onHours = +totalOn.toFixed(2);
				d.offHours = +totalOff.toFixed(2);
				d.sbHours = +totalSb.toFixed(2);
				d.driveHours = +totalD.toFixed(2);
				d.onDriveHours = +totalOnD.toFixed(2);
				//math for the other values
				let numOfDays = days.length;
				d.averageOnHours = +(totalOn / numOfDays).toFixed(2);
				d.averageOffHours = +(totalOff / numOfDays).toFixed(2);
				d.averageSBHours = +(totalSb / numOfDays).toFixed(2);
				d.averageDriveHours = +(totalD / numOfDays).toFixed(2);
				// Only add drivers to the array if they do not have all empty duty status of value -1
				if (countOfEmptyDutyStatus !== Object.keys(data.duty_status_totals).length) {
					dataTable.push(d);
				}
			}
		});
		return [dataTable, accChartData];
	}

	static updateAccChartData(aCD: ChartData, chartData: any) {
		aCD.OFF += chartData.OFF;
		aCD.ON += chartData.ON;
		aCD.DRIVE += chartData.DRIVE;
		aCD.SB += chartData.SB;
		return aCD;
	}
}
