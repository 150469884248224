import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiConfiguration } from 'app/api/api-configuration';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'app/api/base-service';
import * as moment from 'moment';

@Injectable()
export class OdometerService extends BaseService {
	constructor(config: ApiConfiguration, http: HttpClient) { super(config, http); }

	private httpUrl = `${this.rootUrl}/api/v2/odometer-jumps`;

	public getReportDetailsData(queryParams: Params): Observable<any> {
		const params = _.clone(queryParams);
		if (!params.beginDate && !params.endDate) {
			params.endDate = new Date().toISOString();
			params.beginDate = new Date(+moment().subtract(13, 'days').startOf('day').format('x')).toISOString();
		}
		return this.http.get(this.httpUrl, { params });
	}
}
