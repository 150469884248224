<div class="app-wrapper" *ngIf="(activeAcctSelected$ | async); else noAcctSelected">
	<z-side-bar [opened]="contextualHelpOpen" (closed)="turnOffSidebarState()" position="right">
		<mat-spinner mode="indeterminate"
			*ngIf="contextualHelpLoading"
			color="accent"
			diameter="150"
			[style]="{
				height:'150px',
				width:'150px',
				position: 'absolute',
				top:'50%',
				left: '50%',
				transform:
				'translate(-50%, -50%)'
			}"
			strokeWidth="4">
		</mat-spinner>
		<section [innerHTML]="contextualHelpHTML"></section>
	</z-side-bar>
	<header-bar></header-bar>
	<main>
		<info-alert
			[minutesSinceRefresh]="minutesSinceRefresh"
			[hideRefreshBar]="hideRefreshBar"
		></info-alert>
		<router-outlet></router-outlet>
	</main>
    <zui-footer-component></zui-footer-component>
</div>
<ng-template #noAcctSelected style="background-color: none;">
	Please provide an account code in the <i>active</i> parameter in the URL.
</ng-template>
