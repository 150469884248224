import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DriverListService } from './driver-list.service';
import { DispatcherListComponent } from './dispatcher-list.component';
import { ZonarUiSearchableDropdownModule } from '@zonar-ui/searchable-dropdown';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RouterModule,
		MaterialModule,
		ZonarUiSearchableDropdownModule,
		FormsModule,
	],
	exports: [
		DispatcherListComponent,
	],
	providers: [
		DriverListService,
	],
	declarations: [
		DispatcherListComponent,
	],
})
export class DispatcherListModule {
}
