import { Pipe, PipeTransform } from '@angular/core';
import * as convert from 'convert-units';

// A pipe to which converts numeric seconds into a string time period rounded down

@Pipe({
	name: 'humanizeDuration',
})
export class HumanizeDurationPipe implements PipeTransform {
	transform(
		value: number,
		unit: string,
	): string {
		if (!value) {
			return 'Unknown';
		}

		let conversionType = '';
		let convertedValue: any;
		// If the input unit is the same as the conversion unit the input will be returned.
		// Since input could be a string which would screw up the below calculations we coerce type to number and round down
		const convertUnit = (resultingUnit) => Math.floor(Number(convert(value).from(unit).to(resultingUnit)));
		const seconds: number = convertUnit('s');
		const minutes: number = convertUnit('min');
		const hours: number = convertUnit('h');
		const days: number = convertUnit('d');

		if (days >= 1) {
			conversionType = days === 1 ? 'Day' : 'Days';
			convertedValue = days;
		} else if (hours >= 1) {
			conversionType = hours === 1 ? 'Hour' : 'Hours';
			convertedValue = hours;
		} else if (minutes >= 1) {
			conversionType = minutes === 1 ? 'Minute' : 'Minutes';
			convertedValue = minutes;
		} else if (seconds >= 1) {
			conversionType = seconds === 1 ? 'Second' : 'Seconds';
			convertedValue = seconds;
		} else {
			conversionType = '';
			convertedValue = 'Less than 1 second';
		}

		return `${convertedValue} ${conversionType}`;
	}
}
