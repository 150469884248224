import {Component, OnInit} from "@angular/core";
import { Location } from "../../state/app/app-types";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {RootState} from "../../app.module";
import {entityMenusAndUserData, selectedEntitySelector} from "../../state/app/app.selectors";
import {filter, map, take, withLatestFrom} from "rxjs/operators";
import {setSelectedEntity} from "../../state/app/app.actions";
import {BehaviorSubject, combineLatest} from "rxjs";
import {DispatcherLocationToggleService} from "../../dispatcherLocationToggle.service";

@Component({
    selector: 'location-list',
    templateUrl: './location-list.component.html',
    styleUrls: ['./location-list.component.scss'],
})
export class LocationListComponent implements OnInit {
    public locations: Array<Location>;

    public filteredResults: Location[] = [];

    public locationFormGroup: FormGroup = this.fb.group({});

    public selectedEntity = { label: '', value: '', entityType: '' };

    public isCritical$ = new BehaviorSubject(true);

    public isUserAdmin = false;

    constructor(
        public store: Store<RootState>,
        private fb: FormBuilder,
        private dispatcherLocationToggleService: DispatcherLocationToggleService
    ){
        this.locationFormGroup.addControl('locationId', new FormControl());
        this.locationFormGroup.addControl('listedValue', new FormControl());


        combineLatest(
            this.dispatcherLocationToggleService.getLocationEntity(),
            this.store.select(entityMenusAndUserData),
            ).pipe().subscribe(([loc, {isAdmin, locations,
                selectedEntity} ]) => {

            this.isUserAdmin = isAdmin; //determines if the location-dispatcher toggle is present

            //populates the location filter dropdown
            if (!this.locations || this.locations.length === 0) {
                this.locations = Array.isArray(locations) && locations?.length ? locations : [];
                this.filteredResults = this.dropDownFilteredOptions(this.locations);
            }

            // if user isAdmin, ie dispatcher location toggle is present, then update the isCritical based on the entityType,
            // allow location list to save to selectedEntity
            // and display the entity on the location dropdown
            if (selectedEntity && selectedEntity.entityType && selectedEntity.entityType !== 'dispatcherId' && this.isUserAdmin) {
                this.selectedEntity = selectedEntity;
                this.isCritical$.next(selectedEntity.entityType === 'dispatcherId');
                this.locationFormGroup.controls['listedValue'].setValue(this.selectedEntity?.value || '', { emitEvent: false });
                // if the location entity and selected entity are not same,
                // then save it to the service and makes it available to availability and home pages
                if (!loc || (loc && loc.value !== selectedEntity.value)) {
                    this.dispatcherLocationToggleService.setLocationEntity({
                        label: selectedEntity.label,
                        value: selectedEntity.value,
                        entityType: 'locationId',
                    });
                }
            };
            if ((loc && loc.value) && !this.isUserAdmin) {
                //set the location to the last known location
                this.locationFormGroup.controls['listedValue'].setValue(loc?.value || '', { emitEvent: false });
                this.isCritical$.next(false);
            }
        });
    }

    ngOnInit(): void {
        this.locationFormGroup.valueChanges.pipe(map(data => {
            let selectedValue;
            if (this.locations) {
                selectedValue = this.locations.filter((loc) => loc.id === data.listedValue);
            };
            if (selectedValue?.length > 0) {
                // sets the location entity in the service
                this.dispatcherLocationToggleService.setLocationEntity({
                    label: selectedValue[0].label,
                    value: selectedValue[0].id,
                    entityType: 'locationId',
                });
                this.isCritical$.next(false);

                //if user is admin, then it saves entity to the store
                if (this.isUserAdmin) {
                    this.store.dispatch(setSelectedEntity({
                        label: selectedValue[0].label,
                        value: selectedValue[0].id,
                        entityType: 'locationId',
                    }));
                }
            }
        })).subscribe();
    }

    public dropDownFilteredOptions(allArray) {
        if (allArray) {
            return allArray.map((opt) => ({ ...opt, title: opt.label, value: opt.id }));
        }
        return [];
    }

 }
