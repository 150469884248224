import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import {Observable, of, throwError} from 'rxjs';
import { BaseService } from '../../api/base-service';
import { ApiConfiguration } from '../../api/api-configuration';
import { LocalStorageService } from 'app/local-storage.service';
import { AssetMalfunctions, Malfunction, MalfunctionDefinitions} from 'app/reports/malfunctions/malfunction-types';
import { environment } from 'environments/environment';
import {catchError, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class MalfunctionsService extends BaseService {
	private httpUrl = `${this.rootUrl}/api/v2/malfunctions`;

	constructor(config: ApiConfiguration, private localStorageService: LocalStorageService, http?: HttpClient) {
		super(config, http);
	}

	getDriversForMalfunction(asset_id, period_start, period_end): Observable<Object> {
		const driverListUrl = `${environment.urls.hosBackendApi}/api/v1/driver_logins`;
		const queryParams = {
			asset_id,
			period_start,
			account_code: this.localStorageService.get('activeAccount')
		};
		if (period_end) {
			queryParams['period_end'] = period_end;
		}
		return this.http.get(driverListUrl, { params: queryParams });
	}

	// new hos-endpoint
	getMalfunctions(queryParams): Observable<{ data: Malfunction[] | AssetMalfunctions[], total: any }> {
		const params = _.clone(queryParams);
		params.account_code = this.localStorageService.get('activeAccount');
		Object.keys(params).forEach(key => {
			if (params[key] === undefined || params[key] === "undefined") {
				delete params[key];
			}
		});
		return this.http.get(`${environment.urls.hosBackendApi}/api/v1/malfunctions`, { params, observe: 'response' })
			.pipe(map((res: HttpResponse<any>) => {
				return ({
					data: params['group_by_asset'] ? this.processGroupByAssetResponse(res.body): this.processNewApiResponse(res.body),
					total: res.headers.get(`total-count`) ? res.headers.get(`total-count`) : 0,
				});
			}), catchError(error => {
					if (error && error.status) {
					  console.error(
						`Backend returned code ${error.status}, body was: `, error.error);
					  return throwError({msg: error.error.message || error.message, status: error.status});
					}
					console.error('An error occurred:', error.error);
					// Return an observable with a user-facing error message.
					return throwError({msg: 'Something bad happened; please try again later.', status: error?.status});
			}));
	}

	//new kpi-hos-endpoint
	getMalfunctionsKpi(queryParams): Observable<any> {
		const params = _.clone(queryParams);
		params.account_code = this.localStorageService.get('activeAccount');
		return this.http.get(`${environment.urls.hosBackendApi}/api/v1/malfunctions_kpi`, { params });
	}

	//new hos csv endpoint
	getCSV(queryParams: {[key: string]: any}): Observable<any> {
		const params = _.clone(queryParams);
		params.account_code = this.localStorageService.get('activeAccount');
		params.csv = true;
		return this.http.get(`${environment.urls.hosBackendApi}/api/v1/malfunctions`, { params,  responseType: 'blob' })
			.pipe(map((res => res)), catchError((err) => of(err)));
	}

	private processNewApiResponse(data: any[]): Malfunction[] {
		const res: Malfunction[] = [];
		data.forEach(d => {
			const mal: Malfunction = d as Malfunction;
			this.addPeriod(mal);
			this.addCurrentDriver(mal);
			this.addDriverName(mal);
			this.addMalfunctionDriverName(mal);
			this.addMalfunctionType(mal);
			res.push(mal);
		});
		return res;
	}

	private processGroupByAssetResponse(data: any[]): AssetMalfunctions[] {
		data = _.orderBy(data, 'startTime', 'desc');
		const allMalfunctions = this.processNewApiResponse(data);
		let res: AssetMalfunctions[] = [];
		const uniqueAssets: {
			[key: string]: AssetMalfunctions
		} = {};
		_.forEach(allMalfunctions, (mal: Malfunction) => {
			let impactedDrivers = this.getImpactedDrivers(mal);
			impactedDrivers = impactedDrivers.length == 0 ? (!impactedDrivers[0] ? []: impactedDrivers): impactedDrivers;
			if(uniqueAssets[mal.assetNumber]) {
				const assetMal = uniqueAssets[mal.assetNumber];
				assetMal.total = mal.total_malfunctions_in_group;
				assetMal.malfunctions.push(mal);
				assetMal.impactedDrivers = _.uniq([...assetMal.impactedDrivers, ...impactedDrivers]);
				assetMal.types = _.uniq([...assetMal.types, mal.displayMalfunctionType]);
				assetMal.displayImpactedDrivers = this.getMultipleTextOrOriginalVal(assetMal.impactedDrivers);
				assetMal.displayTypes = this.getMultipleTextOrOriginalVal(assetMal.types);
				assetMal.assetDBId = mal.assetId.split(':')[1]
			} else {
				uniqueAssets[mal.assetNumber] = {
					assetId: mal.assetNumber,
					total: 1,
					malfunctions: [mal],
					impactedDrivers: impactedDrivers,
					displayImpactedDrivers: mal.malfunctionDriverName,
					types: [mal.displayMalfunctionType],
					displayTypes: mal.displayMalfunctionType,
					assetDBId: mal.assetId.split(':')[1]
				}
			}
		});
		res = Object.values(uniqueAssets);
		return _.orderBy(res, 'total', 'desc');
	}

	public impactedDriversOrNotFound(m: Partial<Malfunction>) {
		const impactedDrivers = this.getImpactedDrivers(m);
		return impactedDrivers.length !== 0 ? impactedDrivers : [''];
	}

	private getImpactedDrivers(m: Partial<Malfunction>) {
		const impactedDrivers = (m.driverFirstName || m.driverLastName)
			? _.union(m.affectedDriversNames, [`${m.driverFirstName || ''} ${m.driverLastName || ''}`])
			: m.affectedDriversNames;
		return impactedDrivers;
	}

	private getMultipleTextOrOriginalVal(val: Array<string>): string {
		if(val && val.length > 1) {
			return `Multiple(${val.length})`;
		} else if(val && val.length === 1 && val[0]) {
			return val[0];
		} else {
			return '';
		}
	}

	private getDriverName(m: Partial<Malfunction>) {
		const impactedDrivers = this.impactedDriversOrNotFound(m);
		return impactedDrivers.length > 1 ? `Multiple(${impactedDrivers.length})` : impactedDrivers[0];
	}

	private addPeriod(m: Partial<Malfunction>) {
		const endTimeOrNow: number = new Date(m.endTime).valueOf() || new Date().valueOf();
		m.malfunctionPeriod = endTimeOrNow - new Date(m.startTime).valueOf();
	}

	private addDriverName(m: Partial<Malfunction>) {
		if (m.driverFirstName || m.driverLastName) {
			m.driverName = `${m.driverFirstName} ${m.driverLastName}`;
		}
	}

	private addCurrentDriver(m: Partial<Malfunction>) {
		if (m.mostRecentDriver?.firstName || m.mostRecentDriver?.lastName) {
			m.currentDriver = `${m.mostRecentDriver?.firstName || ''} ${m.mostRecentDriver?.lastName || ''}`;
		}
	}

	private addMalfunctionDriverName(m: Partial<Malfunction>): void {
		m.malfunctionDriverName = this.getDriverName(m);
	}

	private addMalfunctionType(m: Partial<Malfunction>): void {
		m.displayMalfunctionType = MalfunctionDefinitions[m.malfunctionType];
	}
}
