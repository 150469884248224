import 'promise-polyfill/src/polyfill';
import 'core-js/es/object';
import {
	AngularWebWorker, bootstrapWorker, OnWorkerInit, Callable,
} from 'angular-web-worker';
import { HoursUtils } from './hours.utils';
/// <reference lib="webworker" />

@AngularWebWorker()
export class HoursWorker {
		@Callable()
	populateModel(dat, params): any {
		return new HoursUtils().populateDataModel(dat, params);
	}

		@Callable()
		populateFilters(dat, params): any {
			return new HoursUtils().buildFilters(dat, params);
		}
}
bootstrapWorker(HoursWorker);
