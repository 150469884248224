<div *ngIf="opened" class="sidebar" role="dialog" aria-modal="true"
    [ngClass]="{'right': position === 'right', 'left': position === 'left', 'opened': opened}"
    [@panelState]="{value: 'visible', params: {transform: transformOptions, transition: transitionOptions}}"
    (@panelState.start)="onAnimationStart($event)">
    <div class="sidebar-header">
        <button mat-icon-button class="sidebar-close" (click)="closeSideBar()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="sidebar-content">
        <ng-content></ng-content>
    </div>
</div>