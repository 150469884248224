export const MalfunctionDurations: any = {
    options: [
        {title: 'All', value: ''},
        {title: 'More than 3 days', value: 259200}, //in secsß
        {title: 'More than 5 days', value: 432000},
        {title: 'More than 7 days', value: 604800}
    ],
    selectedOption: {},
};
export const  malfunctionStatus: { options: Array<any> } = {
    options: [
        {title: 'Active', value: 'true'},
        {title: 'Cleared', value: 'false'},
    ],
};
export const  MalfunctionTypeDropdowns: any = {
    options: [
        // { title: 'All Malfunction Types', value: '' },
        { title: 'Power', value: 'POWER' },
        { title: 'Data Transfer', value: 'DATA_TRANSFER' },
        { title: 'Engine Sync', value: 'ENGINE_SYNCH'},
        { title: 'Position', value: 'POSITIONING' },
        { title: 'Data Recording', value: 'DATA_RECORDING' },
        { title: 'Timing', value: 'TIMING'}
    ],
    selectedOption: {}
};

export const selectedFilter =  { title: 'Previous 6 months', value: 'previous-180-days', numValue: 179 };
