import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'secondsToHoursMinutes' })
export class SecondsToHoursMinutesSeconds implements PipeTransform {
	transform(value: number): string|number {
		if (!value) { return value; }
		return moment.utc(value * 1000).format('HH:mm:ss');
	}
}
