<div class="title-container">
    <div class="title-block">
        <span>{{title}}</span>
        <a href="{{helpUrl}}" 
            target="_blank" rel="noreferrer noopener">
            <mat-icon class="material-icons-outlined">help_outline</mat-icon>
        </a>
    </div>
    <div>
        <mat-slide-toggle
            *ngIf="showToggle"
            [color]="'accent'"
            [checked]="toggleChecked"
            (toggleChange)="emitToggleChange()"
        >{{toggleTitle}}</mat-slide-toggle>
    </div>
</div>