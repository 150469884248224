import { NgModule } from '@angular/core';
import { DateTimeTimezonePipe } from './date-time-timezone.pipe';
import { SecondsToHoursMinutesSeconds } from './seconds-to-hours-minutes-seconds.pipe';
import { MetersToMilesPipe } from './meters-to-miles.pipe';
import { HumanizeDurationPipe } from './humanize-duration.pipe';

@NgModule({
	exports: [
		DateTimeTimezonePipe,
		SecondsToHoursMinutesSeconds,
		MetersToMilesPipe,
		HumanizeDurationPipe,
	],
	declarations: [
		DateTimeTimezonePipe,
		SecondsToHoursMinutesSeconds,
		MetersToMilesPipe,
		HumanizeDurationPipe,
	],
})

export class PipesModule { }
