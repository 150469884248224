import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '../../state/app/app-types';

@Injectable({ providedIn: 'root' })
export class UserService {
	public loaded = new BehaviorSubject<boolean>(false);

	public userData = new Subject<User>();

	constructor(private http: HttpClient) { }

	public getUserDetails() {
		return this.http.get('/api/v1/userDetails').pipe(map((response: User) => {
			this.userData.next(response);
			this.loaded.next(true);
		}));
	}
}
