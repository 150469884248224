import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Params } from '@angular/router';
import {Observable, throwError} from 'rxjs';
import { ApiConfiguration } from 'app/api/api-configuration';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'app/api/base-service';
import {LocalStorageService, SELECTED_ENTITY} from '../../local-storage.service';
import {determineDispatcherOrLocationUtil} from "../../utils/report-utils";
import {environment} from "../../../environments/environment";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class SdsService extends BaseService {
	constructor(config: ApiConfiguration, http: HttpClient, private localStorageService: LocalStorageService) { super(config, http); }

	public getReportDetailsData(queryParams: Params): Observable<any> {
		const now = moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
		const params: { [index: string]: string } = {
			beginDate: queryParams.beginDate || now.clone().subtract(14, 'days').startOf('day').format(),
			endDate: queryParams.endDate || now.clone().add(1, 'hour').format(),
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			limit: queryParams.limit,
			offset: queryParams.offset,
		};
		if (queryParams && queryParams.distanceFilter) {
			params.distanceFilter = queryParams.distanceFilter;
		}
		return this.http.get<any>(`${this.rootUrl}/api/v2/sds`, { params });
	}

	public getSDSReport(queryParams: any): Observable<any> {
		let params = _.clone(queryParams);
		params = {...params, limit: 100};
		params['sds_report'] = true;
		params.account_code = this.localStorageService.get('activeAccount');
		let url = `${environment.urls.hosBackendApi}/api/v1/driver-hours`;

		return this.http.get(url, {params, observe: 'response'}).pipe(map(r => r),
			catchError(error => {
				// Return an observable with a user-facing error message.
				if (error && error.status) {
					console.error(
						`Backend returned code ${error.status}, body was: `, error.error);
					return throwError({msg: error.error.message || error.message, status: error.status});
				}
				console.error('An error occurred:', error);
				return throwError({msg: `Backend returned an error; please try again later.`, status: error?.status});
			}));
	}
}
