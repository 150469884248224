import { Action, createReducer, on } from '@ngrx/store';
import { SdsState } from './sds.types';

import * as sdsActions from './sds.actions';
import { initialSdsState } from './sds.utils';

const sdsReducer = createReducer(
	initialSdsState,
	on(sdsActions.loadSds, (state) => ({
		...state,
		isLoading: true,
		isLoadSuccess: false,
	})),
	on(sdsActions.loadSdsRange, (state) => ({
		...initialSdsState,
		isLoading: true,
		isLoadSuccess: false,
	})),
	on(sdsActions.filterSds, (state) => ({
		...state,
		isLoading: true,
		isLoadSuccess: false,
	})),
	on(sdsActions.loadSdsSuccess, (state, sds) => ({
		...state,
		...sds,
		isLoadSuccess: true,
		isLoading: false,
	})),

);

export function SdsReducer(state: SdsState | undefined, action: Action) {
	return sdsReducer(state, action);
}
