import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import {
	FullLiveStatus, LiveStatusEntry, LiveStatusModel, StatusData,
} from './live-status.reducer';
import { BaseService } from 'app/api/base-service';
import { ApiConfiguration } from 'app/api/api-configuration';
import {environment} from "../../../environments/environment";
import {LocalStorageService, SELECTED_ENTITY} from "../../local-storage.service";
import {determineDispatcherOrLocationUtil} from "../../utils/report-utils";

const STATUS_TYPES = {
	Driving: 'DRIVING',
	'In Violation': 'in_violation',
	'At Risk': 'at_risk',
	'On Duty': 'ON_DUTY',
	'Off Duty': 'OFF_DUTY',
	'Sleeper Berth': 'SB',
};


@Injectable()
class LiveStatusService extends BaseService {
	constructor(config: ApiConfiguration, http: HttpClient, private localStorageService: LocalStorageService,) {
		super(config, http);
	 }

	getLiveStatusPosition(entity?): Observable<any> {
		const url = `${environment.urls.hosBackendApi}/api/v1/live-status-positions`;
		let params = {
			...determineDispatcherOrLocationUtil(entity, 'live-status'),
			account_code: this.localStorageService.get('activeAccount')
		};
		return this.http.get(url, {params});
	}

	static degreesToCompassDirection(degrees) {
		if (isNaN(degrees) || degrees < 0 || degrees > 360) {
			return degrees;
		}
		const directionIndex = Math.floor((degrees / 22.5) + 0.5); // 360 degrees total with 16 directions
		const directionsArray = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
		return directionsArray[(directionIndex % 16)]; // order of directions in array matters to get correct direction
	}


	getStatusesData(stateSlice: LiveStatusModel): StatusData {
		const liveStatusMapData = this.makeMapData(stateSlice);
		const kpiData = _.countBy(liveStatusMapData, (driver) => {
			if (driver.dutyStatus === 'YM' || driver.dutyStatus === 'HR') { return 'ON_DUTY'; }
			if (driver.dutyStatus === 'PC' || driver.dutyStatus === 'WT') { return 'OFF_DUTY'; }

			return driver.dutyStatus;
		});
		const liveStatusKPIData = _.map(STATUS_TYPES, (fieldName, uiLabel) => ({ type: uiLabel, value: _.get(kpiData, fieldName, 0) }));
		return { liveStatusMapData, liveStatusKPIData };
	}

	private makeMapData({ liveStatus,
							violationLiveStatusData
	}: LiveStatusModel): FullLiveStatus[] {
		//removes drivers who don't have dutyStatus, lat and long
		const filteredEntries: any = _.filter(liveStatus, (s) => (s && s.dutyStatus && s.lat && s.long));
		return _.map(filteredEntries, (statusEvent: LiveStatusEntry) => {
			const s: FullLiveStatus = { ...statusEvent, heading: LiveStatusService.degreesToCompassDirection(statusEvent.heading) };
			const driveProfileId = +statusEvent.driverId;
			const indexDriverInViolation = _.indexOf(violationLiveStatusData.driversInViolation, driveProfileId);
			const indexDriverAtRisk = _.indexOf(violationLiveStatusData.driversAtRisk, driveProfileId);
			//overrides duty status if in violation
			s.dutyStatus = indexDriverInViolation !== -1 ? 'in_violation' : indexDriverAtRisk !== -1 ? 'at_risk': s.dutyStatus;
			return s;
		});
	}
}

export { LiveStatusService };
